import { z } from "zod";

const slotSchema = z
  .object({
    template_id: z
      .string()
      .min(1, "Campo obligatorio")
      .min(3, "Necesita mas de 3 caracteres")
      .max(100, "Máximo 100 caracteres"),
    slot_title: z
      .string()
      .min(1, "Campo obligatorio")
      .min(3, "Necesita mas de 3 caracteres")
      .max(100, "Máximo 100 caracteres"),
    slot_description: z
      .string()
      .min(1, "Campo obligatorio")
      .min(3, "Necesita mas de 3 caracteres")
      .max(100, "Máximo 100 caracteres"),
    slot_extra_info_sup: z.string().optional(),
    slot_extra_info_sub: z.string().optional(),
    template_exercises_number: z.number(),
  })
  .catchall(z.string().optional());
export default slotSchema;
