import { useAuthRepository } from "../../repositories/auth/authRepository.js";

import ListAllTemplates from "./components/ListAllTemplates.js";

function Templates() {
  const { user } = useAuthRepository();

  return user.role === "admin" ? (
    <ListAllTemplates />
  ) : (
    <div>No tienes acceso a esta sección</div>
  );
}

export default Templates;
