import { useStore } from "@nanostores/react";
import { useEffect, useState } from "react";

import SessionsRepositoryFirebase from "./SessionsRepositoryFirebase";

const sessionsRepositoryFirebase = new SessionsRepositoryFirebase();

function useSessionsRepository() {
  const [sessionsRepository] = useState(sessionsRepositoryFirebase);
  const sessions = useStore(sessionsRepository.stores.sessions);

  useEffect(() => {
    sessionsRepository.getSessions();
  }, [sessionsRepository]);
  return { sessions, sessionsRepository };
}

export { useSessionsRepository };
