import { Navigate, Route, Routes } from "react-router-dom";

import Activities from "../activities/Activities.js";
import ActivityCreate from "../activities/ActivityCreate.js";
import ActivityProfile from "../activities/ActivityProfile.js";
import UploadExercises from "../admin/upload-exercises/UploadExercises";
import Login from "../auth/Login";
import RequireAuth from "../auth/RequireAuth";
import Companies from "../companies/Companies.js";
import CompanyCreate from "../companies/CompanyCreate.js";
import CompanyProfile from "../companies/CompanyProfile.js";
import Home from "../home/Home.js";
import Layout from "../layout/Layout";
import SearchExercises from "../search-exercises/SearchExercises";
import CreateSessionPDF from "../sessions/components/CreateSessionPdf.js";
import SessionCreate from "../sessions/SessionCreate.js";
import SessionProfile from "../sessions/SessionProfile.js";
import Sessions from "../sessions/Sessions.js";
import TemplateCreate from "../templates/TemplateCreate.js";
import TemplateProfile from "../templates/TemplateProfile.js";
import Templates from "../templates/Templates.js";
import UserCreate from "../users/UserCreate.js";
import UserProfile from "../users/UserProfile.js";
import Users from "../users/Users.js";

import NotFound from "./NotFound";

function Router() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route
        element={
          <RequireAuth>
            <Layout />
          </RequireAuth>
        }
      >
        <Route path="/" element={<Home />} />
        <Route path="/users" element={<Users />} />
        <Route path="/users/create" element={<UserCreate />} />
        <Route path="/users/:user_id" element={<UserProfile />} />
        <Route path="/companies" element={<Companies />} />
        <Route path="/companies/create" element={<CompanyCreate />} />
        <Route path="/companies/:company_id" element={<CompanyProfile />} />
        <Route path="/activities" element={<Activities />} />
        <Route path="/activities/create" element={<ActivityCreate />} />
        <Route path="/activities/:activity_id" element={<ActivityProfile />} />
        <Route path="/templates" element={<Templates />} />
        <Route path="/templates/create" element={<TemplateCreate />} />
        <Route path="/templates/:template_id" element={<TemplateProfile />} />
        <Route path="/sessions" element={<Sessions />} />
        <Route path="/sessions/create" element={<SessionCreate />} />
        <Route
          path="/sessions/pdf/:session_id"
          element={<CreateSessionPDF />}
        />
        <Route path="/sessions/:session_id" element={<SessionProfile />} />
        <Route path="/search-exercises" element={<SearchExercises />} />
        <Route path="/upload-exercises" element={<UploadExercises />} />
      </Route>
      <Route path="not-found" element={<NotFound />} />
      <Route path="*" element={<Navigate to="/not-found" replace />} />
    </Routes>
  );
}

export default Router;
