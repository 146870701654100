import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";

import Spinner from "../../../components/loader/Spinner.js";
import { useCompaniesRepository } from "../../../repositories/companies/companiesRepository.js";
import { useUsersRepository } from "../../../repositories/users/usersRepository.js";
import userRegisteredSchema from "../schemas/userRegisteredSchema.js";

function UpdateUserForm() {
  const { user_id } = useParams();
  const { usersRepository } = useUsersRepository();
  const { companies } = useCompaniesRepository();
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchUser() {
      const userData = await usersRepository.getUserById(user_id);
      setUser(userData);
    }
    fetchUser();
  }, [user_id, usersRepository]);

  const { register, handleSubmit, formState, setValue } = useForm({
    mode: "onTouched",
    resolver: zodResolver(userRegisteredSchema),
    defaultValues: {
      name: user?.name,
      company_id: user?.company_id,
      email: user?.email,
      uid: user?.uid || "", // Asegurarse de que el valor predeterminado sea una cadena vacía si no está presente
      pass: "", // Asegurarse de que el valor predeterminado sea una cadena vacía
      role: user?.role,
    },
  });

  const { errors, isValid } = formState;

  useEffect(() => {
    setValue("name", user?.name);
    setValue("company_id", user?.company_id);
    setValue("email", user?.email);
    setValue("uid", user?.uid || ""); // Asegurarse de que el valor predeterminado sea una cadena vacía si no está presente
    setValue("role", user?.role);
  }, [user, setValue]);

  const handleUpdateUser = async (form) => {
    // Añadir la propiedad modified_at con el tiempo actual
    form.modified_at = new Date().toISOString();

    try {
      await usersRepository.updateUser(user_id, form);
      toast.success("Usuario actualizado correctamente");
      navigate("/users");
    } catch (error) {
      console.error(error);
      toast.error("Error al actualizar el usuario");
    }
  };

  const onInvalid = (errors) => console.error(errors);

  const handleDeleteUser = async () => {
    try {
      await usersRepository.deleteUser(user_id);
      toast.success("Usuario eliminado correctamente");
      navigate("/users");
    } catch (error) {
      console.error(error);
      toast.error("Error al eliminar el usuario");
    }
  };

  if (!user) {
    return <Spinner />;
  }
  return (
    <form onSubmit={handleSubmit(handleUpdateUser, onInvalid)}>
      <div className="space-y-12">
        <div className="border-b border-gray-900/10 pb-12 max-w-4xl mx-auto">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Modificar un usuario
          </h2>
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-4">
              <label
                htmlFor="name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Nombre Completo
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    id="name"
                    name="name"
                    type="text"
                    defaultValue={user.name}
                    {...register("name")}
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
                <p className="h-4 text-sm text-red-500">
                  {errors.name && errors.name.message}
                </p>
              </div>
            </div>
            <div className="sm:col-span-3">
              <label
                htmlFor="role"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Organización
              </label>
              <div className="mt-2">
                <select
                  id="company_id"
                  name="company_id"
                  {...register("company_id")}
                  defaultValue={user.company_id}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                >
                  {companies?.map((company) => (
                    <option key={company.company_id} value={company.company_id}>
                      {company.company_name}
                    </option>
                  ))}
                </select>
              </div>
              <p className="h-4 text-sm text-red-500">
                {errors.company_id && errors.company_id.message}
              </p>
            </div>
            <div className="sm:col-span-4">
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Email
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    defaultValue={user.email}
                    {...register("email")}
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
                <p className="h-4 text-sm text-red-500">
                  {errors.email && errors.email.message}
                </p>
              </div>
            </div>
            <div className="sm:col-span-4">
              <label
                htmlFor="pass"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                UID
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    id="uid"
                    name="uid"
                    type="text"
                    defaultValue={user.uid}
                    {...register("uid")}
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
                <p className="h-4 text-sm text-red-500">
                  {errors.uid && errors.uid.message}
                </p>
              </div>
            </div>
            <div className="sm:col-span-4">
              <label
                htmlFor="pass"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Contraseña Preestablecida
              </label>
              <div className="mt-2">
                <div className="flex bg-gray-300 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    id="pass"
                    name="pass"
                    type="text"
                    defaultValue={user.pass}
                    readOnly
                    {...register("pass")}
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
                <p className="h-4 text-sm text-red-500">
                  {errors.pass && errors.pass.message}
                </p>
              </div>
            </div>
            <div className="sm:col-span-3">
              <label
                htmlFor="role"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Rol
              </label>
              <div className="mt-2">
                <select
                  id="role"
                  name="role"
                  defaultValue={user.role}
                  {...register("role")}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                >
                  <option value={"admin"}>Admin</option>
                  <option value={"trainer"}>Trainer</option>
                  <option value={"editor"}>Editor</option>
                  <option value={"manager"}>Mánager</option>
                </select>
              </div>
              <p className="h-4 text-sm text-red-500">
                {errors.role && errors.role.message}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button
          type="submit"
          disabled={!isValid}
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Modificar
        </button>
        <button
          onClick={handleDeleteUser}
          type="button"
          className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Borrar
        </button>
      </div>
    </form>
  );
}

export default UpdateUserForm;
