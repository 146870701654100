import { useAuthRepository } from "../../repositories/auth/authRepository.js";

import CreateCompanyForm from "./components/CreateCompanyForm.js";

function CompanyCreate() {
  const { user } = useAuthRepository();

  return user.role === "admin" ? (
    <CreateCompanyForm />
  ) : (
    <div>No tienes acceso a esta sección</div>
  );
}

export default CompanyCreate;
