import React, { useEffect, useRef, useState } from "react";

function GenerateUpdateSessionExercises({
  slot,
  register,
  errors,
  handleInputChange,
  defaultExercises,
}) {
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  useEffect(() => {
    // Ejecutar handleInputChange con el defaultValue al montarse el componente
    slot.exercises.forEach((exercise, index) => {
      const defaultValue = defaultExercises[index]
        ? Object.values(defaultExercises[index])[0]
        : "";
      handleInputChange({ target: { value: defaultValue } }, exercise);
    });
  }, []);

  return (
    <div className="mt-20">
      <h2 className="text-xl font-semibold leading-7 text-gray-900">
        {slot.slot_title}
      </h2>
      <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        {slot.exercises.map((exercise, index) => {
          return (
            <div key={exercise.exercise_id} className="sm:col-span-4">
              <label
                htmlFor={`exercise${exercise.exercise_id}_session`}
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Ejercicio: {index + 1}
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    id={`exercise${exercise.exercise_id}_session`}
                    name={`exercise${exercise.exercise_id}_session`}
                    type="number"
                    {...register(`exercise${exercise.exercise_id}_session`, {
                      onChange: (e) => handleInputChange(e, exercise),
                    })}
                    defaultValue={
                      defaultExercises[index]
                        ? Object.values(defaultExercises[index])[0]
                        : ""
                    }
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
                <div className="flex gap-5">
                  {exercise.filter_material && (
                    <p className="h-4 py-5 text-sm text-red-500">
                      {capitalizeFirstLetter(exercise.filter_material)}
                    </p>
                  )}
                  {exercise.filter_body_part && (
                    <p className="h-4 py-5 text-sm text-red-500">
                      {capitalizeFirstLetter(exercise.filter_body_part)}
                    </p>
                  )}
                  {exercise.warning && (
                    <p className="h-4 py-5 text-sm text-yellow-500">
                      {exercise.warning}
                    </p>
                  )}
                  {exercise.repetitions && (
                    <p className="h-4 py-5 text-sm text-green-500">
                      Reps: {exercise.repetitions}
                    </p>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default GenerateUpdateSessionExercises;
