import { child, ref as databaseRef, get, update } from "firebase/database";
import {
  getDownloadURL,
  ref as storageRef,
  uploadBytes,
} from "firebase/storage";
import { atom } from "nanostores";

import firebase from "../../shared/firebase";

class ExercisesRepositoryFirebase {
  constructor() {
    this.stores = {
      loading: atom(true),
      exercises: atom(null),
    };
  }

  async update(exercises, fields) {
    const updates = {};
    const prevExercises = this.stores.exercises.get();
    let formatedId = "";

    for (const exercise of exercises) {
      if (exercise.id && !exercise.id.includes("T")) {
        formatedId = `T${exercise.id.toString().padStart(4, "0")}`;
        exercise.id = formatedId;
      } else {
        formatedId = exercise.id;
      }

      if (fields) {
        for (const field of fields) {
          updates[`/exercises/${formatedId}/${field}`] = exercise[field];

          if (field === "id") {
            updates[`/exercises/${formatedId}/${field}`] = formatedId;
          }
        }
      } else {
        const prevExercise = prevExercises?.get(exercise.id) || {};
        if (prevExercise.image) {
          updates[`/exercises/${exercise.id}`] = {
            image: prevExercise.image,
            extension: prevExercise.extension,
            ...exercise,
          };
        } else {
          updates[`/exercises/${exercise.id}`] = exercise;
        }
      }
    }

    const result = await update(databaseRef(firebase.db), updates);

    this.loadExercises();

    return result;
  }

  async loadExercises() {
    let exercises = [];

    try {
      const exercisesPath = `/exercises/`;
      const exercisesSnapshot = await get(
        child(databaseRef(firebase.db), exercisesPath)
      );

      if (exercisesSnapshot.exists()) {
        exercises = Object.values(exercisesSnapshot.val());
      }
    } catch (error) {
      console.error("ExercisesRepositoryFirebase", error);
    }

    this.stores.exercises.set(
      new Map(
        exercises
          .filter((exercise) => exercise.id)
          .map((exercise) => [exercise.id, exercise])
      )
    );
    this.stores.loading.set(false);
  }

  async uploadImages(imageFiles) {
    const promises = imageFiles.map(async (imageFile) => {
      const [id, extension] = imageFile.name.split(".");

      const subFolder = extension !== "gif" ? `${extension}/` : "";

      const imageRef = storageRef(
        firebase.storage,
        `exercises/${subFolder}${imageFile.name}`
      );

      const snapshot = await uploadBytes(imageRef, imageFile, {
        cacheControl: "public, max-age=604800",
      });
      const image = await getDownloadURL(snapshot.ref);

      return {
        id,
        extension,
        image,
      };
    });

    const exercisesImages = await Promise.all(promises);

    return this.update(exercisesImages, ["image", "extension"]);
  }

  async getExercisesNamesByIds(exercises) {
    const promises = Object.keys(exercises).map(async (exercise) => {
      try {
        const parsedExercise = `T${exercises[exercise]
          .toString()
          .padStart(4, "0")}`;
        const exerciseNamePath = `/exercises/${parsedExercise}/description/`;
        const exerciseNameSnapshot = await get(
          child(databaseRef(firebase.db), exerciseNamePath)
        );

        if (exerciseNameSnapshot.exists()) {
          return exerciseNameSnapshot.val();
        } else {
          return null;
        }
      } catch (error) {
        console.error("ExercisesRepositoryFirebase", error);
        return null;
      }
    });

    const exercisesNames = await Promise.all(promises);
    return exercisesNames.filter((name) => name !== null);
  }

  async getExercisesMaterialByIds(exercises) {
    const promises = Object.keys(exercises).map(async (exercise) => {
      try {
        const parsedExercise = `T${exercises[exercise]
          .toString()
          .padStart(4, "0")}`;
        const exerciseMaterialPath = `/exercises/${parsedExercise}/material/`;
        const exerciseMaterialSnapshot = await get(
          child(databaseRef(firebase.db), exerciseMaterialPath)
        );

        if (exerciseMaterialSnapshot.exists()) {
          return exerciseMaterialSnapshot.val();
        } else {
          return null;
        }
      } catch (error) {
        console.error("ExercisesRepositoryFirebase", error);
        return null;
      }
    });

    const exercisesMaterials = await Promise.all(promises);
    const exercisesMaterialFiltered = exercisesMaterials.filter(
      (material) => material !== null
    );

    // Flatten the array of arrays and get unique values
    const uniqueMaterials = [...new Set(exercisesMaterialFiltered.flat())];

    return uniqueMaterials;
  }
}

export default ExercisesRepositoryFirebase;
