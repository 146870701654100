import { useAuthRepository } from "../../repositories/auth/authRepository.js";

import CreateUserForm from "./components/CreateUserForm.js";

function UserCreate() {
  const { user } = useAuthRepository();

  return user.role === "admin" ? (
    <CreateUserForm />
  ) : (
    <div>No tienes acceso a esta sección</div>
  );
}

export default UserCreate;
