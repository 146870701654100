import {
  child,
  ref as databaseRef,
  get,
  remove,
  update,
} from "firebase/database";
import { atom } from "nanostores";

import firebase from "../../shared/firebase";

class UsersRepositoryFirebase {
  constructor() {
    this.stores = {
      users: atom(null),
    };

    this.getUsers();
  }

  async getUsers() {
    let users = [];
    try {
      const usersPath = `/users/`;
      const usersSnapshot = await get(
        child(databaseRef(firebase.db), usersPath)
      );

      if (usersSnapshot.exists()) {
        users = Object.values(usersSnapshot.val());

        for (const user of users) {
          let company;
          const companyPath = `/companies/${user.company_id}`;
          const companySnapshot = await get(
            child(databaseRef(firebase.db), companyPath)
          );
          if (companySnapshot.exists()) {
            company = companySnapshot.val();
          }
          user.company = company;
        }
        this.stores.users.set(users);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getUserById(id) {
    let user = null;
    try {
      const userPath = `/users/${id}`;
      const userSnapshot = await get(child(databaseRef(firebase.db), userPath));

      if (userSnapshot.exists()) {
        user = userSnapshot.val();

        // Obtener el ID de la compañía del objeto user
        const companyId = user.company_id;

        if (companyId) {
          const companyPath = `/companies/${companyId}`;
          const companySnapshot = await get(
            child(databaseRef(firebase.db), companyPath)
          );

          if (companySnapshot.exists()) {
            const company = companySnapshot.val();
            user.company_name = company.company_name; // Añadir el nombre de la compañía al objeto user
          }
        }
      }
    } catch (error) {
      console.error(error);
    }

    return user;
  }

  async updateUser(id, data) {
    try {
      const userPath = `/users/${id}`;
      const userRef = child(databaseRef(firebase.db), userPath);

      // Actualizar la base de datos con data
      await update(userRef, data);
    } catch (error) {
      console.error(error);
    }
  }

  async createUser(id, data) {
    try {
      const usersPath = `/users/${id}`;
      const usersRef = child(databaseRef(firebase.db), usersPath);

      // Crear un nuevo documento en la base de datos
      await update(usersRef, data);
    } catch (error) {
      console.error(error);
    }
  }

  async deleteUser(id) {
    try {
      const userPath = `/users/${id}`;
      const userRef = child(databaseRef(firebase.db), userPath);

      // Eliminar el documento de la base de datos
      await remove(userRef);
    } catch (error) {
      console.error(error);
    }
  }
}

export default UsersRepositoryFirebase;
