import { useAuthRepository } from "../../repositories/auth/authRepository.js";

import CreateActivityForm from "./components/CreateActivityForm.js";

function ActivityCreate() {
  const { user } = useAuthRepository();

  return user.role === "admin" ? (
    <CreateActivityForm />
  ) : (
    <div>No tienes acceso a esta sección</div>
  );
}

export default ActivityCreate;
