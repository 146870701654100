import { z } from "zod";

const userLoginSchema = z.object({
  email: z
    .string()
    .min(1, "Campo obligatorio")
    .email("Debe ser un email válido"),
  pass: z
    .string()
    .min(8, "Debe contener entre 8 y 16 caracteres")
    .max(16, "Debe contener entre 8 y 16 caracteres"),
});

export default userLoginSchema;
