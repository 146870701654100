import React, { useState } from "react";

import { useAuthRepository } from "../../repositories/auth/authRepository.js";
import SearchExercises from "../search-exercises/SearchExercises.js";

import UpdateSessionForm from "./components/UpdateSessionForm.js";

function SessionProfile() {
  const { user } = useAuthRepository();
  const [toggle, setToggle] = useState(false);

  const handleToggle = () => {
    setToggle((prevToggle) => !prevToggle);
  };

  return user.role === "admin" ? (
    <div className="flex flex-col">
      <button
        onClick={handleToggle}
        className="m-10 px-4 py-2 bg-blue-500 text-white rounded self-end sticky top-10"
      >
        {toggle ? "Mostrar Sesión" : "Mostrar Buscador"}
      </button>
      <div
        style={{
          display: toggle ? "none" : "block",
          height: "100vh",
          overflow: "auto",
        }}
      >
        <UpdateSessionForm />
      </div>
      <div
        style={{
          display: toggle ? "block" : "none",
          height: "100vh",
          overflow: "auto",
        }}
      >
        <SearchExercises />
      </div>
    </div>
  ) : (
    <div>No tienes acceso a esta sección</div>
  );
}

export default SessionProfile;
