import { useStore } from "@nanostores/react";
import { useEffect, useState } from "react";

import CompaniesRepositoryFirebase from "./CompaniesRepositoryFirebase";

const companiesRepositoryFirebase = new CompaniesRepositoryFirebase();

function useCompaniesRepository() {
  const [companiesRepository] = useState(companiesRepositoryFirebase);
  const companies = useStore(companiesRepository.stores.companies);

  useEffect(() => {
    companiesRepository.getCompanies();
  }, [companiesRepository]);
  return { companies, companiesRepository };
}

export { useCompaniesRepository };
