import { useAuthRepository } from "../../repositories/auth/authRepository.js";

import ListAllSessions from "./components/ListAllSessions.js";
import ListAllSessionsTrainer from "./components/ListAllSessionsTrainer.js";

function Sessions() {
  const { user } = useAuthRepository();

  return user.role === ("admin" || "editor") ? (
    <ListAllSessions />
  ) : (
    <ListAllSessionsTrainer />
  );
}

export default Sessions;
