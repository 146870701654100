import { useStore } from "@nanostores/react";
import { useEffect, useState } from "react";

import TemplatesRepositoryFirebase from "./TemplatesRepositoryFirebase";

const templatesRepositoryFirebase = new TemplatesRepositoryFirebase();

function useTemplatesRepository() {
  const [templatesRepository] = useState(templatesRepositoryFirebase);
  const templates = useStore(templatesRepository.stores.templates);

  useEffect(() => {
    templatesRepository.getTemplates();
  }, [templatesRepository]);
  return { templates, templatesRepository };
}

export { useTemplatesRepository };
