import { Link } from "react-router-dom";

import logoSmall from "../../../assets/logo-small.png";
import { useAuthRepository } from "../../../repositories/auth/authRepository.js";
import { useCompaniesRepository } from "../../../repositories/companies/companiesRepository";

function ListAllCompanies() {
  const { user } = useAuthRepository();
  const { companies } = useCompaniesRepository();

  return user.role === "admin" ? (
    <div className="max-w-4xl mx-auto">
      <h2 className="text-2xl font-semibold p-5">Organizaciones</h2>
      <ul role="list" className="divide-y divide-gray-100 mb-10">
        {companies?.map((company) => (
          <Link
            key={company?.company_id}
            to={`/companies/${company?.company_id}`}
            className="block"
          >
            <li className="flex justify-between gap-x-6 py-5">
              <div className="flex min-w-0 gap-x-4 flex-1">
                <img
                  alt=""
                  src={company?.company_logo || logoSmall}
                  className="h-12 w-12 flex-none rounded-full object-cover bg-gray-50"
                />
                <div className="flex min-w-0 gap-x-4 flex-1">
                  <div className="min-w-0 flex-auto">
                    <p className="text-sm font-semibold leading-6 text-gray-900">
                      {company?.company_name}
                    </p>
                    <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                      {company?.email}
                    </p>
                  </div>
                </div>
                <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                  <p className="text-sm leading-6 text-gray-900">
                    {company?.comercial_name}
                  </p>
                  <div className="mt-1 flex items-center gap-x-1.5">
                    <p className="text-xs leading-5 text-gray-500">
                      {company?.address}
                    </p>
                  </div>
                </div>
              </div>
            </li>
          </Link>
        ))}
      </ul>
      <Link
        to="/companies/create"
        className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Añadir +
      </Link>
    </div>
  ) : (
    <div>No tienes acceso a esta sección</div>
  );
}

export default ListAllCompanies;
