"use client";

import {
  CloseButton,
  Dialog,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import {
  Bars3Icon,
  BuildingOffice2Icon,
  ChartBarSquareIcon,
  DocumentTextIcon,
  EnvelopeIcon,
  UserIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useState } from "react";
import { Link } from "react-router-dom";

import logoSmall from "../../assets/3_Logo bz55 COLOR-NEGRO.png";
import { useAuthRepository } from "../../repositories/auth/authRepository.js";

const config = [
  {
    name: "Organizaciones",
    description: "Configura tus organizaciones",
    to: "/companies",
    icon: BuildingOffice2Icon,
  },
  {
    name: "Usuarios",
    description: "Configura tus usuarios",
    to: "/users",
    icon: UserIcon,
  },
  {
    name: "Actividades",
    description: "Configura tus actividades",
    to: "/activities",
    icon: ChartBarSquareIcon,
  },
  {
    name: "Plantillas",
    description: "Configura tus plantillas",
    to: "/templates",
    icon: EnvelopeIcon,
  },
  {
    name: "Sesiones",
    description: "Configura tus sesiones",
    to: "/sessions",
    icon: DocumentTextIcon,
  },
];

export default function NavWithBurguerButton() {
  const { user, authRepository } = useAuthRepository();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  if (user.role !== "admin") {
    return (
      <header className="bg-white">
        <nav
          aria-label="Global"
          className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
        >
          <div className="flex lg:flex-1">
            <Link to="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img alt="logo de bz55" src={logoSmall} className="h-8 w-auto" />
            </Link>
          </div>
          <div className="flex lg:flex-1">
            <div className="lg:flex lg:flex-1 lg:justify-end">
              <button
                onClick={authRepository.logout}
                className="inline-flex w-auto justify-center rounded-md hover:bg-red-600 px-3 py-2 text-sm font-semibold text-red-600 hover:text-white"
              >
                Cerrar sesión
              </button>
            </div>
          </div>
        </nav>
      </header>
    );
  }

  return (
    <header className="bg-white">
      <nav
        aria-label="Global"
        className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
      >
        <div className="flex lg:flex-1">
          <Link to="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Your Company</span>
            <img alt="logo de bz55" src={logoSmall} className="h-8 w-auto" />
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>
        <PopoverGroup className="hidden lg:flex lg:gap-x-12">
          <Popover className="relative">
            <PopoverButton className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
              Configuración
              <ChevronDownIcon
                aria-hidden="true"
                className="h-5 w-5 flex-none text-gray-400"
              />
            </PopoverButton>
            <PopoverPanel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in">
              <div className="p-4">
                {config.map((item) => (
                  <div
                    key={item.name}
                    className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                  >
                    <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                      <item.icon
                        aria-hidden="true"
                        className="h-6 w-6 text-gray-600 group-hover:text-indigo-600"
                      />
                    </div>
                    <div className="flex-auto">
                      <PopoverButton
                        as={Link}
                        to={item.to}
                        className="block font-semibold text-gray-900"
                      >
                        {item.name}
                        <span className="absolute inset-0" />
                      </PopoverButton>
                      <p className="mt-1 text-gray-600">{item.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </PopoverPanel>
          </Popover>
          <Link
            to="/search-exercises"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Buscar ejercicios
          </Link>
          <Link
            to="/upload-exercises"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Subir ejercicios
          </Link>
        </PopoverGroup>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <button
            onClick={authRepository.logout}
            className="inline-flex w-auto justify-center rounded-md hover:bg-red-600 px-3 py-2 text-sm font-semibold text-red-600 hover:text-white"
          >
            Cerrar sesión
          </button>
        </div>
      </nav>
      <Dialog
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
        className="lg:hidden"
      >
        <div className="fixed inset-0 z-10" />
        <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img alt="logo de bz55" src={logoSmall} className="h-8 w-auto" />
            </a>
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <Disclosure as="div" className="-mx-3">
                  <DisclosureButton className="group flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                    Configuración
                    <ChevronDownIcon
                      aria-hidden="true"
                      className="h-5 w-5 flex-none group-data-[open]:rotate-180"
                    />
                  </DisclosureButton>
                  <DisclosurePanel className="mt-2 space-y-2">
                    {config.map((item) => (
                      <DisclosureButton
                        key={item.name}
                        as={Link}
                        onClick={() => setMobileMenuOpen(false)}
                        to={item.to}
                        className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                      >
                        {item.name}
                      </DisclosureButton>
                    ))}
                  </DisclosurePanel>
                </Disclosure>
                <CloseButton
                  as={Link}
                  to="/search-exercises"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Buscar ejercicios
                </CloseButton>
                <CloseButton
                  as={Link}
                  to="/upload-exercises"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Subir ejercicios
                </CloseButton>
              </div>
              <div className="py-6">
                <button
                  onClick={authRepository.logout}
                  className="inline-flex w-auto justify-center rounded-md hover:bg-red-600 px-3 py-2 text-sm font-semibold text-red-600 hover:text-white"
                >
                  Cerrar sesión
                </button>
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  );
}
