import { useStore } from "@nanostores/react";
import { useEffect, useState } from "react";

import UsersRepositoryFirebase from "./UsersRepositoryFirebase";

const usersRepositoryFirebase = new UsersRepositoryFirebase();

function useUsersRepository() {
  const [usersRepository] = useState(usersRepositoryFirebase);
  const users = useStore(usersRepository.stores.users);

  useEffect(() => {
    usersRepository.getUsers();
  }, [usersRepository]);
  return { users, usersRepository };
}

export { useUsersRepository };
