import { useAuthRepository } from "../../repositories/auth/authRepository.js";

import UpdateActivityForm from "./components/UpdateActivityForm.js";

function ActivityProfile() {
  const { user } = useAuthRepository();

  return user.role === "admin" ? (
    <UpdateActivityForm />
  ) : (
    <div>No tienes acceso a esta sección</div>
  );
}

export default ActivityProfile;
