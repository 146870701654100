import { PhotoIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import { zodResolver } from "@hookform/resolvers/zod";
import { set } from "firebase/database";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

import Spinner from "../../../components/loader/Spinner.js";
import { useActivitiesRepository } from "../../../repositories/activities/activitiesRepository.js";
import { useAuthRepository } from "../../../repositories/auth/authRepository.js";
import { useSessionsRepository } from "../../../repositories/sessions/sessionsRepository.js";
import { useTemplatesRepository } from "../../../repositories/templates/templatesRepository.js";
import { useSearchExercises } from "../../search-exercises/useSearchExercises.js";

import GenerateSessionExercises from "./GenerateSessionExercises.js";
// import templateSchema from "../schemas/templateSchema.js";

function CreateSessionForm() {
  const { user } = useAuthRepository();
  const { templates, templatesRepository } = useTemplatesRepository();
  const [currentTemplateId, setCurrentTemplateId] = useState("");
  const [currentActivityId, setCurrentActivityId] = useState("");
  const [slots, setSlots] = useState([]);
  const [totalExercises, setTotalExercises] = useState([]);
  const [isAble, setIsAble] = useState(false);
  const [selectedTemplates, setSelectedTemplates] = useState([]);

  const { exercises: allExercises } = useSearchExercises();
  const [inputValues, setInputValues] = useState({});
  const [allFieldsValid, setAllFieldsValid] = useState({});
  const { sessionsRepository } = useSessionsRepository();

  const months = [
    "ENERO",
    "FEBRERO",
    "MARZO",
    "ABRIL",
    "MAYO",
    "JUNIO",
    "JULIO",
    "AGOSTO",
    "SEPTIEMBRE",
    "OCTUBRE",
    "NOVIEMBRE",
    "DICIEMBRE",
  ];
  const { activities, activitiesRepository } = useActivitiesRepository();
  const navigate = useNavigate();
  const sortedSlots = slots?.sort((a, b) => a.order - b.order);

  const { register, unregister, handleSubmit, formState } = useForm({
    mode: "onTouched",
    // resolver: zodResolver(templateSchema),
  });

  const { errors, isValid } = formState;

  // Obtener el mes actual
  const currentMonth = new Date()
    .toLocaleString("default", { month: "long" })
    .toUpperCase();
  const defaultMonth = months.find((month) => month === currentMonth);

  const saveExercises = (slot) => {
    setTotalExercises((prevExercises) => {
      let newExercises = [...prevExercises];
      slot.exercises.forEach((exercise) => {
        newExercises.push(exercise);
      });
      return newExercises;
    });
  };

  useEffect(() => {
    const fetchSlots = async () => {
      try {
        const slots = await templatesRepository.getTemplateSlotsByTemplateId(
          currentTemplateId
        );
        setSlots(slots);
        setTotalExercises([]);
        slots?.forEach((slot) => {
          saveExercises(slot);
        });
      } catch (error) {
        console.error("Error fetching slots:", error);
      }
    };

    fetchSlots();
  }, [currentTemplateId, templatesRepository]);

  useEffect(() => {
    // Desregistrar campos anteriores
    slots?.forEach((slot) => {
      slot.exercises.forEach((exercise, index) => {
        unregister(`exercise${exercise.exercise_id}_session`);
      });
    });

    // Crear nuevos campos
    const newFields = [];
    slots?.forEach((slot) => {
      slot.exercises.forEach((exercise, index) => {
        newFields.push(exercise.exercise_id);
      });
    });
    setInputValues(newFields.reduce((acc, id) => ({ ...acc, [id]: "" }), {}));
    setAllFieldsValid(
      newFields.reduce((acc, id) => ({ ...acc, [id]: false }), {})
    );
  }, [slots, unregister]);

  // Efecto para verificar si todos los campos son válidos
  useEffect(() => {
    const allValid = Object.values(allFieldsValid).every(Boolean);
    setIsAble(allValid);
  }, [allFieldsValid]);

  // Función para formatear el ID
  const formatId = (number) => {
    return `T${number.toString().padStart(4, "0")}`;
  };

  // Efecto para recalcular plantillas cuando cambie la actividad
  useEffect(() => {
    const fetchTemplatesForActivity = () => {
      try {
        const selectedTemplates = templates?.filter(
          (template) => template.activity_id === currentActivityId
        );
        setSelectedTemplates(selectedTemplates);
      } catch (error) {
        console.error("Error fetching templates:", error);
      }
    };
    fetchTemplatesForActivity();
  }, [currentActivityId, templates]);

  // Función para comprobar el valor del input
  const checkFilter = (value, exercise) => {
    const formattedId = formatId(value);

    const matchingExerciseMuscleGroup = allExercises.find(
      (ex) =>
        ex.id === formattedId &&
        (ex.muscleGroup.includes(exercise.filter_body_part) ||
          exercise.filter_body_part === "")
    );
    if (matchingExerciseMuscleGroup) {
      const matchingExerciseMaterial = allExercises.find(
        (ex) =>
          ex.id === formattedId &&
          (ex.material.includes(exercise.filter_material) ||
            exercise.filter_material === "")
      );
      if (matchingExerciseMaterial) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  // Manejar el cambio del input
  const handleInputChange = (e, exercise) => {
    const value = e.target.value;
    setInputValues((prevValues) => ({
      ...prevValues,
      [exercise.exercise_id]: value,
    }));

    const isValid = checkFilter(value, exercise);
    setAllFieldsValid((prevValues) => ({
      ...prevValues,
      [exercise.exercise_id]: isValid,
    }));
  };

  const handleCreateSession = async (form) => {
    try {
      const user_id = user.uid;
      const session_id = crypto.randomUUID();
      const created_at = new Date().toISOString();
      const currentYear = new Date().getFullYear();
      const sessionDate = `${form.day} de ${form.month} de ${currentYear}`;
      const status = "Pendiente";

      // Renombrar los ejercicios según el orden de entrada
      const exerciseKeys = Object.keys(form).filter(
        (key) => key.startsWith("exercise") && key.endsWith("_session")
      );
      const renamedExercises = {};
      exerciseKeys.forEach((key, index) => {
        renamedExercises[`exercise_session_${index + 1}`] = form[key];
      });

      // Eliminar las claves originales de los ejercicios
      exerciseKeys.forEach((key) => {
        delete form[key];
      });

      const formUpdated = {
        ...form,
        ...renamedExercises,
        user_id,
        session_id,
        created_at,
        sessionDate,
        status,
      };
      await sessionsRepository.createSession(session_id, formUpdated);
      toast.success("Plantilla creada correctamente.");
      navigate("/sessions");
    } catch (error) {
      toast.error("Error al crear la plantilla.");
    }
  };

  return (
    <form onSubmit={handleSubmit(handleCreateSession)}>
      <div className="space-y-12 max-w-4xl mx-auto">
        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Crear una sesión
          </h2>
          <div className="sm:col-span-4">
            <label
              htmlFor="template_name"
              className="mt-5 block text-sm font-medium leading-6 text-gray-900"
            >
              Actividad
            </label>
            <div className="mt-2">
              <select
                id="template_id"
                name="template_id"
                {...register("activity_id", {
                  onChange: (e) => {
                    setCurrentActivityId(e.target.value);
                  },
                })}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
              >
                <option defaultChecked>ELIGE UNA ACTIVIDAD</option>
                {activities?.map((activity) => (
                  <option
                    key={activity.activity_id}
                    value={activity.activity_id}
                  >
                    {activity.activity_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="sm:col-span-4">
            <label
              htmlFor="template_name"
              className="mt-5 block text-sm font-medium leading-6 text-gray-900"
            >
              Plantilla
            </label>
            <div className="mt-2">
              <select
                id="template_id"
                name="template_id"
                {...register("template_id", {
                  onChange: (e) => {
                    setCurrentTemplateId(e.target.value);
                  },
                })}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
              >
                <option defaultChecked>ELIGE UNA PLANTILLA</option>
                {selectedTemplates?.map((template) => (
                  <option
                    key={template.template_id}
                    value={template.template_id}
                  >
                    {template.template_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-4">
              <label
                htmlFor="day"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Día
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    id="day"
                    name="day"
                    type="number"
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    {...register("day", {
                      valueAsNumber: true,
                      min: {
                        value: 1,
                        message: "El día debe ser al menos 1",
                      },
                      max: {
                        value: 31,
                        message: "El día no puede ser mayor que 31",
                      },
                      required: "El día es obligatorio",
                    })}
                  />
                </div>
                {errors.day && (
                  <p className="h-4 text-sm text-red-500">
                    {errors.day.message}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="sm:col-span-4">
            <label
              htmlFor="template_name"
              className="mt-5 block text-sm font-medium leading-6 text-gray-900"
            >
              Mes
            </label>
            <div className="mt-2">
              <select
                id="month"
                name="month"
                defaultValue={defaultMonth}
                {...register("month")}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
              >
                {months?.map((month, index) => (
                  <option key={index} value={month}>
                    {month}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {sortedSlots?.map((slot) => (
            <GenerateSessionExercises
              key={slot.slot_id}
              slot={slot}
              register={register}
              errors={errors}
              handleInputChange={handleInputChange}
              inputValues={inputValues}
              allFieldsValid={allFieldsValid}
            />
          ))}
        </div>
      </div>
      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button
          type="submit"
          disabled={!isAble}
          className={`rounded-md ${
            !isAble ? "bg-red-600" : "bg-indigo-500"
          } px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2`}
        >
          Crear
        </button>
      </div>
    </form>
  );
}

export default CreateSessionForm;
