import { PhotoIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";

import Spinner from "../../../components/loader/Spinner.js";
import { useTemplatesRepository } from "../../../repositories/templates/templatesRepository.js";
import { useUsersRepository } from "../../../repositories/users/usersRepository.js";
import slotSchema from "../schemas/slotSchema.js";

import GenerateSlotExercises from "./GenerateSlotExercises.js";

function CreateTemplateSlotForm({ template_id, onSlotCreated }) {
  const { templatesRepository } = useTemplatesRepository();
  const [templateExercisesNumber, setTemplateExercisesNumber] = useState(0);
  const [fields, setFields] = useState([]);
  const [template, setTemplate] = useState("");

  const navigate = useNavigate();

  const { register, handleSubmit, formState, unregister } = useForm({
    mode: "onTouched",
    resolver: zodResolver(slotSchema),
  });

  const { errors, isValid } = formState;

  useEffect(() => {
    const fetchTemplate = async () => {
      try {
        const template = await templatesRepository.getTemplateById(template_id);
        setTemplate(template);
      } catch (error) {
        console.error("Error fetching template name:", error);
      }
    };

    fetchTemplate();
  }, [template_id, templatesRepository]);

  useEffect(() => {
    // Desregistrar campos anteriores
    fields.forEach((_, index) => {
      unregister(`filter_material_${index}`);
      unregister(`filter_body_part_${index}`);
    });

    // Crear nuevos campos
    const newFields = [];
    for (let i = 0; i < templateExercisesNumber; i++) {
      newFields.push(i);
    }
    setFields(newFields);
  }, [templateExercisesNumber]);

  const handleCreateSlot = async (form) => {
    try {
      // Crear el objeto slot
      const slot_id = crypto.randomUUID();
      const slot = {
        template_id: form.template_id,
        template_exercises_number: form.template_exercises_number,
        slot_title: form.slot_title,
        slot_extra_info_sup: form.slot_extra_info_sup,
        slot_extra_info_sub: form.slot_extra_info_sub,
        slot_description: form.slot_description,
        slot_id: slot_id,
        created_at: new Date().toISOString(),
      };

      // Crear los objetos exercises
      const exercises = [];
      for (let i = 0; i < form.template_exercises_number; i++) {
        const exercise_id = crypto.randomUUID();
        const exercise = {
          exercise_id: exercise_id,
          slot_id: slot_id,
          filter_body_part: form[`exercise${i}_filter_body_part`],
          filter_material: form[`exercise${i}_filter_material`],
          repetitions: form[`exercise${i}_repetitions`],
          warning: form[`exercise${i}_warning`],
          order: i + 1,
          created_at: new Date().toISOString(),
        };
        exercises.push(exercise);
      }
      await templatesRepository.createSlotWithExercises(
        slot_id,
        slot,
        exercises
      );
      toast.success("Bloque creado correctamente");
      onSlotCreated();
    } catch (error) {
      console.error(error);
      toast.error("Error al crear el bloque");
    }
  };

  const onInvalid = (errors) => console.error(errors);

  return (
    <form
      className="mt-20"
      onSubmit={handleSubmit(handleCreateSlot, onInvalid)}
    >
      <div className="space-y-12">
        <div className="border-b border-gray-900/10 pb-12 max-w-4xl mx-auto">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Crear un bloque
          </h2>
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
            <div className="sm:col-span-4">
              <label
                htmlFor="slot_title"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Título
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    id="slot_title"
                    name="slot_title"
                    type="text"
                    {...register("slot_title")}
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
                <p className="h-4 text-sm text-red-500">
                  {errors.slot_title && errors.slot_title.message}
                </p>
              </div>
            </div>
            <div className="sm:col-span-4">
              <label
                htmlFor="template_id"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Plantilla
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    id="template_name"
                    name="template_name"
                    type="text"
                    value={template?.template_name || ""}
                    readOnly
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
            <input
              type="hidden"
              id="template_id"
              name="template_id"
              value={template_id}
              {...register("template_id")}
            />
            <div className="sm:col-span-4">
              <label
                htmlFor="slot_description"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Descripción
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    id="slot_description"
                    name="slot_description"
                    type="text"
                    {...register("slot_description")}
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
                <p className="h-4 text-sm text-red-500">
                  {errors.slot_description && errors.slot_description.message}
                </p>
              </div>
            </div>
            <div className="sm:col-span-4">
              <label
                htmlFor="slot_extra_info_sup"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Información extra superior
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    id="slot_extra_info_sup"
                    name="slot_extra_info_sup"
                    type="text"
                    {...register("slot_extra_info_sup")}
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
                <p className="h-4 text-sm text-red-500">
                  {errors.slot_extra_info_sup &&
                    errors.slot_extra_info_sup.message}
                </p>
              </div>
            </div>
            <div className="sm:col-span-4">
              <label
                htmlFor="slot_extra_info_sub"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Información extra inferior
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    id="slot_extra_info_sub"
                    name="slot_extra_info_sub"
                    type="text"
                    {...register("slot_extra_info_sub")}
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
                <p className="h-4 text-sm text-red-500">
                  {errors.slot_extra_info_sub &&
                    errors.slot_extra_info_sub.message}
                </p>
              </div>
            </div>
            <div className="sm:col-span-4">
              <label
                htmlFor="template_exercises_number"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Número de ejercicios
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    id="template_exercises_number"
                    name="template_exercises_number"
                    type="number"
                    min={1}
                    {...register("template_exercises_number", {
                      valueAsNumber: true,
                      min: {
                        value: 1,
                        message: "El número mínimo de ejercicios es 1",
                      },
                      onChange: (e) =>
                        setTemplateExercisesNumber(Number(e.target.value)),
                    })}
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
                <p className="h-4 text-sm text-red-500">
                  {errors.template_exercises_number &&
                    errors.template_exercises_number.message}
                </p>
              </div>
              {fields.length > 0 && (
                <GenerateSlotExercises
                  fields={fields}
                  register={register}
                  errors={errors}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button
          type="submit"
          disabled={!isValid}
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Crear Bloque
        </button>
      </div>
    </form>
  );
}

export default CreateTemplateSlotForm;
