import { useAuthRepository } from "../../repositories/auth/authRepository.js";

import UpdateTemplateForm from "./components/UpdateTemplateForm.js";

function TemplateProfile() {
  const { user } = useAuthRepository();

  return user.role === "admin" ? (
    <UpdateTemplateForm />
  ) : (
    <div>No tienes acceso a esta sección</div>
  );
}

export default TemplateProfile;
