import { useEffect, useRef, useState } from "react";
import { FaArrowDown } from "react-icons/fa";
import { FaArrowUp } from "react-icons/fa";
import { HiCheckCircle } from "react-icons/hi";
import { HiExclamationCircle } from "react-icons/hi";
import { HiMinusCircle } from "react-icons/hi";
import { RxButton } from "react-icons/rx";
import { Link } from "react-router-dom";

import { useActivitiesRepository } from "../../../repositories/activities/activitiesRepository.js";
import { useAuthRepository } from "../../../repositories/auth/authRepository.js";
import { useSessionsRepository } from "../../../repositories/sessions/sessionsRepository.js";
import { useTemplatesRepository } from "../../../repositories/templates/templatesRepository.js";

function ListAllSessionsTrainer() {
  const { user } = useAuthRepository();
  const { sessions, sessionsRepository } = useSessionsRepository();
  const { activities } = useActivitiesRepository();
  const { templates } = useTemplatesRepository();
  const [sessionsSorted, setSessionsSorted] = useState([]);
  const modalRef = useRef(null);
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [currentActivityId, setCurrentActivityId] = useState("");
  const [currentTemplateId, setCurrentTemplateId] = useState("");
  const [dateSession, setDateSession] = useState("");
  const [isArchived, setIsArchived] = useState(false);

  useEffect(() => {
    fetchSessions();
  }, []);

  useEffect(() => {
    if (sessions) {
      setSessionsSorted(sessions);
    }
  }, [sessions]);

  const fetchSessions = async () => {
    await sessionsRepository.getSessions();
  };

  // Efecto para recalcular plantillas cuando cambie la actividad
  useEffect(() => {
    const fetchTemplatesForActivity = () => {
      try {
        const selectedTemplates = templates?.filter(
          (template) => template?.activity_id === currentActivityId
        );
        setSelectedTemplates(selectedTemplates);
      } catch (error) {
        console.error("Error fetching templates:", error);
      }
    };
    fetchTemplatesForActivity();
  }, [currentActivityId, templates]);

  useEffect(() => {
    if (dateSession) {
      const filteredSessions = sessions?.filter((session) =>
        session?.sessionDate.toLowerCase().includes(dateSession)
      );
      setSessionsSorted(filteredSessions);
    } else {
      setSessionsSorted(sessions);
    }
  }, [dateSession, sessions]);

  useEffect(() => {
    if (currentTemplateId && currentActivityId !== "ELIGE UNA PLANTILLA") {
      const filteredSessions = sessions?.filter(
        (session) => session?.template_id === currentTemplateId
      );
      setSessionsSorted(filteredSessions);
    } else {
      return;
    }
  }, [currentTemplateId]);

  useEffect(() => {
    if (currentActivityId && currentActivityId !== "TODAS LAS ACTIVIDADES") {
      const filteredSessions = sessions?.filter(
        (session) => session?.activity_id === currentActivityId
      );
      setSessionsSorted(filteredSessions);
    } else {
      setSessionsSorted(sessions);
    }
  }, [currentActivityId]);

  const [openModalIndex, setOpenModalIndex] = useState(null);

  const handleButtonClick = (index) => {
    setOpenModalIndex(index);
  };

  const handleCloseModal = () => {
    setOpenModalIndex(null);
  };

  const handleUpdatePending = async (id) => {
    sessionsRepository.updateSessionStatus(id, "Pendiente");
    await fetchSessions();
  };

  const handleUpdateRepeat = async (id) => {
    sessionsRepository.updateSessionStatus(id, "Repetir");
    await fetchSessions();
  };

  const handleUpdateChecked = async (id) => {
    sessionsRepository.updateSessionStatus(id, "Revisada");
    await fetchSessions();
  };

  const handleUpdateArchive = async (id) => {
    sessionsRepository.updateSessionStatus(id, "Archivada");
    await fetchSessions();
  };

  const handleDownloadJson = async (id) => {
    sessionsRepository.exportSessionToJson(id);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleCloseModal();
      }
    };

    if (openModalIndex !== null) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openModalIndex]);

  const parseDate = (dateString) => {
    const months = {
      ENERO: 0,
      FEBRERO: 1,
      MARZO: 2,
      ABRIL: 3,
      MAYO: 4,
      JUNIO: 5,
      JULIO: 6,
      AGOSTO: 7,
      SEPTIEMBRE: 8,
      OCTUBRE: 9,
      NOVIEMBRE: 10,
      DICIEMBRE: 11,
    };

    const [day, , month, , year] = dateString.split(" ");
    return new Date(year, months[month.toUpperCase()], parseInt(day));
  };

  const sortSessions = (sessions, order) => {
    return sessions.sort((a, b) => {
      const dateA = parseDate(a.sessionDate);
      const dateB = parseDate(b.sessionDate);
      return order === "asc" ? dateA - dateB : dateB - dateA;
    });
  };

  const handleSort = (order) => {
    const sortedSessions = sortSessions([...sessionsSorted], order);
    setSessionsSorted(sortedSessions);
  };

  return user.role === "trainer" ? (
    <div className="max-w-4xl mx-auto">
      <h2 className="text-2xl font-semibold p-5">Sesiones</h2>
      <div className="flex gap-5 justify-around my-5">
        <input
          type="text"
          placeholder="Buscar por fecha..."
          className="block flex-1 border-1 border-color-gray-900 rounded-md bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
          onChange={(e) => {
            setDateSession(e.target.value);
          }}
        />
        <div className="flex gap-x-5">
          <button
            onClick={() => handleSort("asc")}
            className="flex justify-center items-center text-sm font-semibold leading-6 text-gray-900"
          >
            <FaArrowUp />
          </button>
          <button
            onClick={() => handleSort("desc")}
            className="flex justify-center items-center text-sm font-semibold leading-6 text-gray-900"
          >
            <FaArrowDown />
          </button>
          <div className="flex justify-center items-center">
            {!isArchived ? (
              <button
                onClick={async () => {
                  await sessionsRepository.getSessionsArchived();
                  setIsArchived(true);
                }}
                className="flex justify-center items-center text-sm font-semibold leading-6 text-gray-900"
              >
                Archivadas
              </button>
            ) : (
              <button
                onClick={async () => {
                  await sessionsRepository.getSessions();
                  setIsArchived(false);
                }}
                className="flex justify-center items-center text-sm font-semibold leading-6 text-gray-900"
              >
                Sin archivar
              </button>
            )}
          </div>
        </div>
        <div className="sm:col-span-4">
          <div className="mt-2">
            <select
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
              id="template_id"
              name="template_id"
              onChange={(e) => {
                setCurrentActivityId(e.target.value);
              }}
            >
              <option defaultChecked>TODAS LAS ACTIVIDADES</option>
              {activities?.map((activity) => (
                <option key={activity.activity_id} value={activity.activity_id}>
                  {activity.activity_name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="mt-2">
          <select
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
            id="template_id"
            name="template_id"
            onChange={(e) => {
              setCurrentTemplateId(e.target.value);
            }}
            disabled={
              !currentActivityId ||
              currentActivityId === "TODAS LAS ACTIVIDADES"
            }
          >
            {selectedTemplates?.map((template) => (
              <option key={template.template_id} value={template.template_id}>
                {template.template_name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <ul role="list" className="divide-y divide-gray-100 mb-10">
        {sessionsSorted &&
          sessionsSorted
            .filter((session) => session?.user_id === user.uid)
            .map((session) => {
              return (
                <div key={session?.session_id} className="flex justify-between">
                  <Link
                    to={`/sessions/${session?.session_id}`}
                    className="block"
                  >
                    <li className="flex justify-between gap-x-6 py-5">
                      <div className="flex min-w-0 gap-x-4 flex-1">
                        <div className="flex min-w-0 gap-x-4 flex-1">
                          <div className="min-w-0 flex-auto">
                            <p className="text-sm font-semibold leading-6 text-gray-900">
                              {session?.sessionDate}
                            </p>
                            <p className="mt-1 truncate text-md leading-5 text-gray-500">
                              {session?.template?.template_name} -{" "}
                              {session?.user?.name || "Usuario eliminado"}
                            </p>
                            <p className="mt-1 truncate text-sm leading-5 text-gray-500">
                              {session?.status}
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                  </Link>
                </div>
              );
            })}
      </ul>
      <Link
        to="/sessions/create"
        className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
      >
        Añadir +
      </Link>
    </div>
  ) : (
    <div>No tienes acceso a esta sección</div>
  );
}

export default ListAllSessionsTrainer;
