import { PhotoIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

import { useActivitiesRepository } from "../../../repositories/activities/activitiesRepository.js";
import { useTemplatesRepository } from "../../../repositories/templates/templatesRepository.js";
import templateSchema from "../schemas/TemplateSchema.js";

function CreateTemplateForm() {
  const { templatesRepository } = useTemplatesRepository();
  const { activities } = useActivitiesRepository();
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const navigate = useNavigate();

  const { register, handleSubmit, formState } = useForm({
    mode: "onTouched",
    resolver: zodResolver(templateSchema),
  });

  const { errors, isValid } = formState;

  const handleCreateTemplate = async (form) => {
    try {
      const template_id = crypto.randomUUID();
      const created_at = new Date().toISOString();
      const formWithTemplateId = {
        ...form,
        template_id: template_id,
        created_at: created_at,
      };
      await templatesRepository.createTemplate(template_id, formWithTemplateId);
      toast.success("Plantilla creada correctamente.");
      navigate("/templates");
    } catch (error) {
      toast.error("Error al crear la plantilla.");
    }
  };

  return (
    <form onSubmit={handleSubmit(handleCreateTemplate)}>
      <div className="space-y-12 max-w-4xl mx-auto">
        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Crear una plantilla
          </h2>
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-4">
              <label
                htmlFor="template_name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Nombre de la plantilla
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    id="template_name"
                    name="template_name"
                    type="text"
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    {...register("template_name")}
                  />
                </div>
                {errors.template_name && (
                  <p className="h-4 text-sm text-red-500">
                    {errors.template_name.message}
                  </p>
                )}
              </div>
            </div>
            <div className="sm:col-span-4">
              <label
                htmlFor="template_name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Actividad
              </label>
              <div className="mt-2">
                <select
                  id="activity_id"
                  name="activity_id"
                  {...register("activity_id")}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                >
                  {activities?.map((activity) => (
                    <option
                      key={activity?.activity_id}
                      value={activity?.activity_id}
                    >
                      {activity?.activity_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="sm:col-span-4">
              <label
                htmlFor="composition_name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Composition Name
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    id="composition_name"
                    name="composition_name"
                    type="text"
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    {...register("composition_name")}
                  />
                </div>
                {errors.composition_name && (
                  <p className="h-4 text-sm text-red-500">
                    {errors.composition_name.message}
                  </p>
                )}
              </div>
            </div>
            <div className="sm:col-span-4">
              <label
                htmlFor="composition_name_2"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Composition Name 2
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    id="composition_name_2"
                    name="composition_name_2"
                    type="text"
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    {...register("composition_name_2")}
                  />
                </div>
                {errors.composition_name_2 && (
                  <p className="h-4 text-sm text-red-500">
                    {errors.composition_name_2.message}
                  </p>
                )}
              </div>
            </div>
            <div className="col-span-full max-w-xl">
              <label
                htmlFor="cover-photo"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Logo de la plantilla
              </label>
              <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                <div className="flex flex-col items-center text-center">
                  <PhotoIcon
                    aria-hidden="true"
                    className="mx-auto h-12 w-12 text-gray-300"
                  />
                  <div className="mt-4 flex text-sm leading-6 text-gray-600">
                    <label
                      htmlFor="image"
                      className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                    >
                      <span>Sube una imagen</span>
                      <input
                        id="image"
                        type="file"
                        className="sr-only"
                        accept="image/png, image/jpeg, image/gif"
                        max="10MB"
                        {...register("image", {
                          onChange: (e) => {
                            setIsFileUploaded(true);
                          },
                        })}
                      />
                    </label>
                  </div>
                  <p className="text-xs leading-5 text-gray-600">
                    PNG, JPG, GIF máximo 10MB
                  </p>
                  {isFileUploaded && (
                    <p className="text-xs leading-5 text-green-600">
                      Nueva imagen seleccionada
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button
          type="submit"
          disabled={!isValid}
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Crear
        </button>
      </div>
    </form>
  );
}

export default CreateTemplateForm;
