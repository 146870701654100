import {
  child,
  ref as databaseRef,
  get,
  remove,
  update,
} from "firebase/database";
import { atom } from "nanostores";

import firebase from "../../shared/firebase";

class UsersRepositoryFirebase {
  constructor() {
    this.stores = {
      activities: atom(null),
    };

    this.getActivities();
  }

  async getActivities() {
    let activities = [];
    try {
      const activitiesPath = `/activities/`;
      const activitiesSnapshot = await get(
        child(databaseRef(firebase.db), activitiesPath)
      );

      if (activitiesSnapshot.exists()) {
        activities = Object.values(activitiesSnapshot.val());

        for (const activity of activities) {
          let company;
          const companyPath = `/companies/${activity.company_id}`;
          const companySnapshot = await get(
            child(databaseRef(firebase.db), companyPath)
          );
          if (companySnapshot.exists()) {
            company = companySnapshot.val();
          }
          activity.company = company;
        }
        this.stores.activities.set(activities);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getActivityById(id) {
    let activity = null;
    try {
      const activityPath = `/activities/${id}`;
      const activitySnapshot = await get(
        child(databaseRef(firebase.db), activityPath)
      );

      if (activitySnapshot.exists()) {
        activity = activitySnapshot.val();

        // Obtener el ID de la compañía del objeto activity
        const companyId = activity.company_id;

        if (companyId) {
          const companyPath = `/companies/${companyId}`;
          const companySnapshot = await get(
            child(databaseRef(firebase.db), companyPath)
          );

          if (companySnapshot.exists()) {
            const company = companySnapshot.val();
            activity.company_name = company.company_name; // Añadir el nombre de la compañía al objeto user
          }
        }
      }
    } catch (error) {
      console.error(error);
    }

    return activity;
  }

  async updateActivity(id, data) {
    try {
      const activityPath = `/activities/${id}`;
      const activityRef = child(databaseRef(firebase.db), activityPath);

      // Actualizar la base de datos con data
      await update(activityRef, data);
    } catch (error) {
      console.error(error);
    }
  }

  async createActivity(id, data) {
    try {
      const activitiesPath = `/activities/${id}`;
      const activityRef = child(databaseRef(firebase.db), activitiesPath);

      // Crear un nuevo documento en la base de datos
      await update(activityRef, data);
    } catch (error) {
      console.error(error);
    }
  }

  async deleteActivity(id) {
    try {
      const activityPath = `/activities/${id}`;
      const activityRef = child(databaseRef(firebase.db), activityPath);

      // Eliminar el documento de la base de datos
      await remove(activityRef);
    } catch (error) {
      console.error(error);
    }
  }
}

export default UsersRepositoryFirebase;
