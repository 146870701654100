import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import Logo from "../../../assets/4_Logo bz55 NEGRO.png";
import { useExercisesRepository } from "../../../repositories/exercises/exercisesRepository.js";
import { useSessionsRepository } from "../../../repositories/sessions/sessionsRepository.js";
import { useTemplatesRepository } from "../../../repositories/templates/templatesRepository.js";

function CreateSessionPDF() {
  const { session_id } = useParams();
  const { templatesRepository } = useTemplatesRepository();
  const { sessionsRepository } = useSessionsRepository();
  const { exercisesRepository } = useExercisesRepository();
  const [session, setSession] = useState(null);
  const [slots, setSlots] = useState(null);
  const [completeSlots, setCompleteSlots] = useState(null);
  const [exerciseSessions, setExerciseSessions] = useState(null);
  const [exerciseNames, setExerciseNames] = useState(null);
  const [exerciseMaterial, setExerciseMaterial] = useState(null);
  const pdfRef = useRef();

  useEffect(() => {
    async function fetchSession() {
      const sessionData = await sessionsRepository.getSessionById(session_id);
      setSession(sessionData);
    }
    fetchSession();
  }, [session_id, sessionsRepository]);

  useEffect(() => {
    async function fetchSlot() {
      const slotsData = await templatesRepository.getTemplateSlotsByTemplateId(
        session?.template?.template_id
      );
      const sortedSlots = slotsData?.sort((a, b) => a.order - b.order);
      setSlots(sortedSlots);
    }
    fetchSlot();
  }, [session, sessionsRepository]);

  useEffect(() => {
    if (session) {
      const exerciseSessions = extractExerciseSessions(session);
      setExerciseSessions(exerciseSessions);
    }
  }, [session, sessionsRepository]);

  useEffect(() => {
    if (exerciseSessions) {
      async function fetchExerciseNames() {
        const exerciseNamesData =
          await exercisesRepository.getExercisesNamesByIds(exerciseSessions);
        setExerciseNames(exerciseNamesData);
      }
      fetchExerciseNames();
    }
  }, [exerciseSessions, exercisesRepository]);

  useEffect(() => {
    if (exerciseSessions) {
      async function fetchExerciseMaterial() {
        const exerciseMaterialData =
          await exercisesRepository.getExercisesMaterialByIds(exerciseSessions);
        setExerciseMaterial(exerciseMaterialData);
      }
      fetchExerciseMaterial();
    }
  }, [exerciseSessions, exercisesRepository]);

  useEffect(() => {
    if (slots && exerciseNames) {
      let exerciseIndex = 0;
      const updatedSlots = slots.map((slot) => {
        const updatedExercises = slot.exercises.map((exercise) => {
          const updatedExercise = {
            ...exercise,
            name: exerciseNames[exerciseIndex],
          };
          exerciseIndex += 1;
          return updatedExercise;
        });
        return {
          ...slot,
          exercises: updatedExercises,
        };
      });
      setCompleteSlots(updatedSlots);
    }
  }, [slots, exerciseNames]);

  const extractExerciseSessions = (session) => {
    const exerciseSessions = Object.keys(session)
      .filter((key) => key.startsWith("exercise_session_"))
      .sort((a, b) => {
        const getOrder = (key) =>
          parseInt(key.match(/exercise_session_(\d+)/)[1], 10);
        return getOrder(a) - getOrder(b);
      })
      .reduce((obj, key) => {
        obj[key] = session[key];
        return obj;
      }, {});

    return exerciseSessions;
  };

  const generatePDF = async () => {
    try {
      const input = pdfRef.current; // Capturar el contenido del div referenciado

      // Usar html2canvas para capturar el contenido del div como una imagen
      const canvas = await html2canvas(input, {
        logging: true,
        useCORS: true, //to enable cross origin perms
      });
      const imgData = canvas.toDataURL("image/png");

      // Crear un nuevo documento PDF
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      // Calcular las dimensiones de la imagen para que se ajuste al tamaño A4
      const imgProps = pdf.getImageProperties(imgData);
      const imgWidth = pdfWidth;
      const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;

      // Si la imagen es más alta que el tamaño de la página, escalarla para que encaje en una sola página
      const scale = Math.min(
        pdfWidth / imgProps.width,
        pdfHeight / imgProps.height
      );
      const scaledWidth = imgProps.width * scale;
      const scaledHeight = imgProps.height * scale;

      // Centrar la imagen en la página
      const xOffset = (pdfWidth - scaledWidth) / 2;
      const yOffset = (pdfHeight - scaledHeight) / 2;

      pdf.addImage(imgData, "PNG", xOffset, yOffset, scaledWidth, scaledHeight);

      // Guardar el PDF
      pdf.save(`${session.sessionDate}.pdf`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="flex justify-end relative">
        <button
          className="m-10 px-4 py-2 bg-blue-500 text-white rounded sticky top-10 z-20"
          onClick={generatePDF}
        >
          Descargar PDF
        </button>
      </div>
      <div className="max-w-4xl py-20 px-20 text-sm" ref={pdfRef}>
        <div className="flex justify-around px-20 py-20">
          <img
            className="w-48"
            src={session?.template?.template_logo}
            alt={session?.template?.name}
          />
          <img
            className="w-48 object-contain"
            src={Logo}
            alt={"Logo de la empresa"}
          />
        </div>
        {completeSlots?.map((slot, index) => (
          <div key={slot?.slot_id} className="py-2 px-10">
            <h2 className="bg-black text-white p-2 font-bold">
              {slot?.slot_title} - {slot?.slot_extra_info_sub}
            </h2>
            <ul>
              {slot?.exercises?.map((exercise, index) => (
                <li
                  key={exercise?.exercise_id}
                  className="flex justify-between"
                >
                  <div className="flex">
                    <p className="p-2 font-semibold">{index + 1}</p>
                    <p className="p-2 font-semibold">
                      {exercise?.name?.toUpperCase()}
                    </p>
                  </div>
                  <p className="p-2 font-semibold">{exercise?.repetitions}</p>
                </li>
              ))}
            </ul>
            <h2 className="p-2 font-bold">{slot?.slot_extra_info_sub}</h2>
          </div>
        ))}
        <div className="py-2 px-10">
          <h2 className="bg-black text-white p-2 font-bold">MATERIAL</h2>
          <ul>
            {exerciseMaterial?.map((material, index) => (
              <li
                key={`${material}${index}li`}
                className="flex justify-between"
              >
                <div className="flex">
                  <p className="p-2 font-semibold">
                    - {material.toUpperCase()}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}

export default CreateSessionPDF;
