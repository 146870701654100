import { useAuthRepository } from "../../repositories/auth/authRepository.js";

import UpdateCompanyForm from "./components/UpdateCompanyForm.js";

function CompanyProfile() {
  const { user } = useAuthRepository();

  return user.role === "admin" ? (
    <UpdateCompanyForm />
  ) : (
    <div>No tienes acceso a esta sección</div>
  );
}

export default CompanyProfile;
