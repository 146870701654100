import { child, get, ref } from "@firebase/database";
import { persistentAtom } from "@nanostores/persistent";
import {
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from "firebase/auth";

import User from "../../models/auth/User";
import firebase from "../../shared/firebase";

const googleAuthProvider = new GoogleAuthProvider();

class AuthRepositoryFirebase {
  constructor() {
    this.stores = {
      user: persistentAtom("AuthRepositoryFirebase.user", null, {
        encode: (user) => (user ? user.toString() : ""),
        decode: (value) => (value ? new User(JSON.parse(value)) : null),
      }),
    };

    onAuthStateChanged(firebase.auth, async (user) => {
      if (!user) {
        this.stores.user.set(null);

        return;
      }

      const role = await this.#getUserRole(user.uid);

      this.stores.user.set(new User({ ...user, role }));
    });
  }

  async #getUserRole(userUid) {
    let userRole = "";

    try {
      const rolePath = `users/${userUid}/role`;
      const roleSnapshot = await get(child(ref(firebase.db), rolePath));

      if (roleSnapshot.exists()) {
        userRole = roleSnapshot.val();
      }
    } catch (error) {}

    return userRole;
  }

  async loginWithGoogle() {
    try {
      await signInWithPopup(firebase.auth, googleAuthProvider);
    } catch (error) {
      // TODO: Handle errors
      console.error(error);
    }
  }

  async signInWithEmailAndPassword(email, password) {
    try {
      const userCredential = await signInWithEmailAndPassword(
        firebase.auth,
        email,
        password
      );
      const user = userCredential.user;
      this.stores.user.set(new User(user));
      return user;
    } catch (error) {
      console.error("Error signing in with email and password:", error);
      throw error;
    }
  }

  async logout() {
    try {
      await signOut(firebase.auth);
    } catch (error) {
      // TODO: Handle errors
      console.error(error);
    }
  }
}

export default AuthRepositoryFirebase;
