import { z } from "zod";

const companySchema = z.object({
  comercial_name: z
    .string()
    .min(1, "Campo obligatorio")
    .min(3, "Necesita mas de 3 caracteres")
    .max(50, "Máximo 50 caracteres"),
  company_name: z
    .string()
    .min(1, "Campo obligatorio")
    .min(3, "Necesita mas de 3 caracteres")
    .max(50, "Máximo 50 caracteres"),
  image: z.any().optional(),
  email: z
    .string()
    .min(1, "Campo obligatorio")
    .email("Debe ser un email válido"),
  address: z
    .string()
    .min(1, "Campo obligatorio")
    .min(3, "Necesita mas de 3 caracteres")
    .max(50, "Máximo 50 caracteres"),
  CIF: z
    .string()
    .min(1, "Campo obligatorio")
    .min(9, "Debe contener 9 caracteres")
    .max(9, "Debe contener 9 caracteres"),
  phone_number: z
    .string()
    .min(1, "Campo obligatorio")
    .min(9, "Debe tener al menos 9 números")
    .max(15, "No puede tener más de 15 números"),
});

export default companySchema;
