import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast, Toaster } from "sonner";

import { useAuthRepository } from "../../repositories/auth/authRepository";
import userLoginSchema from "../users/schemas/userLoginSchema.js";

import DecorativeImageGrid from "./DecorativeImageGrid";

function Login() {
  const { user, authRepository } = useAuthRepository();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate("/", { replace: true });
    }
  }, [navigate, user]);

  const { register, handleSubmit, formState } = useForm({
    mode: "onTouched",
    resolver: zodResolver(userLoginSchema),
  });

  const { errors, isValid } = formState;

  const onInvalid = (errors) => console.error(errors);

  const handleLogUser = async (form) => {
    try {
      await authRepository.signInWithEmailAndPassword(form.email, form.pass);
      toast.success("Inicio de sesión correcto");
    } catch (error) {
      toast.error("Error al iniciar sesión");
    }
  };

  return (
    <div className="relative overflow-visible max-w-7xl mx-auto w-full px-4 sm:px-6 lg:px-8 flex flex-col">
      <div className="pb-80 pt-16 sm:pb-40 sm:pt-24 lg:pb-48 lg:pt-40 min-h-full flex flex-col justify-center">
        <div className="relative max-w-7xl px-4 sm:static sm:px-6 lg:px-8">
          <div className="sm:max-w-lg">
            <h1 className="font text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl mt-10">
              Incia sesión
            </h1>
            <p className="mt-8 text-xl text-gray-500">
              ¡Bienvenido a <strong>Bz55 Explorer</strong>! La plataforma
              integral para gestionar y optimizar las sesiones de Bz55. 💪
            </p>
          </div>
          <div className="mt-10">
            <button
              onClick={authRepository.loginWithGoogle}
              className="inline-block rounded-lg border border-transparent bg-blue-600 px-8 py-3 text-center font-medium text-white hover:bg-blue-700"
            >
              Continuar con Google
            </button>
          </div>
          <form onSubmit={handleSubmit(handleLogUser, onInvalid)}>
            <div className="space-y-12">
              <div className="border-b border-gray-900/10 pb-12">
                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-4">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Email
                    </label>
                    <div className="mt-2">
                      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          {...register("email")}
                          className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        />
                      </div>
                      <p className="h-4 text-sm text-red-500">
                        {errors.email && errors.email.message}
                      </p>
                    </div>
                  </div>
                  <div className="sm:col-span-4">
                    <label
                      htmlFor="pass"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Contraseña
                    </label>
                    <div className="mt-2">
                      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                        <input
                          id="pass"
                          name="pass"
                          type="password"
                          {...register("pass")}
                          className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        />
                      </div>
                      <p className="h-4 text-sm text-red-500">
                        {errors.pass && errors.pass.message}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-6 flex items-center justify-start gap-x-6">
              <button
                type="submit"
                disabled={!isValid}
                className="inline-block rounded-lg border border-transparent bg-blue-600 px-8 py-3 text-center font-medium text-white hover:bg-blue-700"
              >
                Continuar
              </button>
            </div>
          </form>
        </div>
      </div>
      <Toaster />
      <DecorativeImageGrid />
    </div>
  );
}

export default Login;
