import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";

import { useActivitiesRepository } from "../../../repositories/activities/activitiesRepository.js";
import { useCompaniesRepository } from "../../../repositories/companies/companiesRepository.js";
import activitySchema from "../schemas/activitySchema.js";

function CreateActivityForm() {
  const { activitiesRepository } = useActivitiesRepository();
  const { companies } = useCompaniesRepository();
  const navigate = useNavigate();

  const { register, handleSubmit, formState } = useForm({
    mode: "onTouched",
    resolver: zodResolver(activitySchema),
  });

  const { errors, isValid } = formState;

  const handleCreateActivity = async (form) => {
    try {
      // Añadir id a la actividad
      form.activity_id = crypto.randomUUID();

      // Añadir la propiedad created_at con el tiempo actual
      form.created_at = new Date().toISOString();
      await activitiesRepository.createActivity(form.activity_id, form);
      toast.success("Actividad actualizado correctamente");
      navigate("/activities");
    } catch (error) {
      console.error(error);
      toast.error("Error al actualizar la actividad");
    }
  };

  const onInvalid = (errors) => console.error(errors);

  return (
    <form onSubmit={handleSubmit(handleCreateActivity, onInvalid)}>
      <div className="space-y-12">
        <div className="border-b border-gray-900/10 pb-12 max-w-4xl mx-auto">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Crear una actividad
          </h2>
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-4">
              <label
                htmlFor="activity_name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Nombre de la actividad
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    id="activity_name"
                    name="activity_name"
                    type="text"
                    {...register("activity_name")}
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
                <p className="h-4 text-sm text-red-500">
                  {errors.activity_name && errors.activity_name.message}
                </p>
              </div>
            </div>
            <div className="sm:col-span-3">
              <label
                htmlFor="role"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Organización
              </label>
              <div className="mt-2">
                <select
                  id="company_id"
                  name="company_id"
                  {...register("company_id")}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                >
                  {companies?.map((company) => (
                    <option
                      key={company?.company_id}
                      value={company?.company_id}
                    >
                      {company?.company_name}
                    </option>
                  ))}
                </select>
              </div>
              <p className="h-4 text-sm text-red-500">
                {errors.company_id && errors.company_id.message}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button
          type="submit"
          disabled={!isValid}
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Crear
        </button>
      </div>
    </form>
  );
}

export default CreateActivityForm;
