import { z } from "zod";

const activitySchema = z.object({
  activity_name: z
    .string()
    .min(1, "Campo obligatorio")
    .min(3, "Necesita mas de 3 caracteres")
    .max(50, "Máximo 50 caracteres"),
  company_id: z
    .string()
    .min(1, "Campo obligatorio")
    .min(3, "Necesita mas de 3 caracteres")
    .max(100, "Máximo 100 caracteres"),
});

export default activitySchema;
