import { useAuthRepository } from "../../repositories/auth/authRepository.js";
import ListAllSessions from "../sessions/components/ListAllSessions.js";
import ListAllSessionsTrainer from "../sessions/components/ListAllSessionsTrainer.js";

function Home() {
  const { user } = useAuthRepository();

  return user.role === "admin" || user.role === "editor" ? (
    <ListAllSessions />
  ) : (
    <ListAllSessionsTrainer />
  );
}

export default Home;
