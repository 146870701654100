import clsx from "clsx";
import { NavLink, Outlet } from "react-router-dom";
import { Toaster } from "sonner";

// import Logo from "../../components/logo/Logo";
import { useAuthRepository } from "../../repositories/auth/authRepository";

import NavWithBurguerButton from "./NavWithBurguerButton.js";

const navItems = [
  { label: "Inicio", to: "/" },
  { label: "Organizaciones", to: "/companies" },
  { label: "Usuarios", to: "/users" },
  { label: "Buscador", to: "/search-exercises" },
  {
    label: "Subir ejercicios",
    to: "/upload-exercises",
  },
];

function Layout() {
  const { user, authRepository } = useAuthRepository();

  return (
    <div className="max-w-7xl mx-auto w-full px-4 sm:px-6 lg:px-8 flex flex-col">
      <NavWithBurguerButton />
      <main className="pt-6 pb-4 lg:pb-6">
        <Outlet />
        <Toaster />
      </main>
    </div>
  );
}

export default Layout;
