import { useAuthRepository } from "../../repositories/auth/authRepository.js";

import ListAllCompanies from "./components/ListAllCompanies.js";

function Companies() {
  const { user } = useAuthRepository();

  return user.role === "admin" ? (
    <ListAllCompanies />
  ) : (
    <div>No tienes acceso a esta sección</div>
  );
}

export default Companies;
