import { useAuthRepository } from "../../repositories/auth/authRepository.js";

import ListAllUsers from "./components/ListAllUsers.js";

function Users() {
  const { user } = useAuthRepository();

  return user.role === "admin" ? (
    <ListAllUsers />
  ) : (
    <div>No tienes acceso a esta sección</div>
  );
}

export default Users;
