import { Link } from "react-router-dom";

import { useUsersRepository } from "../../../repositories/users/usersRepository";

function ListAllUsers() {
  const { users } = useUsersRepository();
  return (
    <div className="max-w-4xl mx-auto">
      <h2 className="text-2xl font-semibold p-5">Usuarios</h2>
      <ul role="list" className="divide-y divide-gray-100 mb-10">
        {users?.map((user) => (
          <Link to={`/users/${user?.uid}`} key={user?.uid} className="block">
            <li className="flex justify-between gap-x-6 py-5">
              <div className="flex min-w-0 gap-x-4">
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold leading-6 text-gray-900">
                    {user?.name}
                  </p>
                  <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                    {user?.email}
                  </p>
                </div>
              </div>
              <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                <p className="text-sm leading-6 text-gray-900 font-medium">
                  {user?.role.toUpperCase()}
                </p>
                <div className="mt-1 flex items-center gap-x-1.5">
                  <p className="text-xs leading-5 text-gray-500">
                    {user?.company?.company_name}
                  </p>
                </div>
              </div>
            </li>
          </Link>
        ))}
      </ul>
      <Link
        to="/users/create"
        className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Añadir +
      </Link>
    </div>
  );
}

export default ListAllUsers;
