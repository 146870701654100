import { PhotoIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import { zodResolver } from "@hookform/resolvers/zod";
import { get, set } from "firebase/database";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";

import Spinner from "../../../components/loader/Spinner.js";
import { useActivitiesRepository } from "../../../repositories/activities/activitiesRepository.js";
import { useAuthRepository } from "../../../repositories/auth/authRepository.js";
import { useSessionsRepository } from "../../../repositories/sessions/sessionsRepository.js";
import { useTemplatesRepository } from "../../../repositories/templates/templatesRepository.js";
import { useSearchExercises } from "../../search-exercises/useSearchExercises.js";

import GenerateSessionExercises from "./GenerateSessionExercises.js";
import GenerateUpdateSessionExercises from "./GenerateUpdateSessionExercises.js";
// import templateSchema from "../schemas/templateSchema.js";

function UpdateSessionForm() {
  const { session_id } = useParams();
  const { user } = useAuthRepository();
  const { templates, templatesRepository } = useTemplatesRepository();
  const [currentTemplateId, setCurrentTemplateId] = useState("");
  const [slots, setSlots] = useState([]);
  const [totalExercises, setTotalExercises] = useState([]);
  const [isAble, setIsAble] = useState(false);

  const { exercises: allExercises } = useSearchExercises();
  const [inputValues, setInputValues] = useState({});
  const [allFieldsValid, setAllFieldsValid] = useState({});
  const { sessionsRepository } = useSessionsRepository();

  const [session, setSession] = useState(null);
  const [defaultExercises, setDefaultExercises] = useState([]);

  const months = [
    "ENERO",
    "FEBRERO",
    "MARZO",
    "ABRIL",
    "MAYO",
    "JUNIO",
    "JULIO",
    "AGOSTO",
    "SEPTIEMBRE",
    "OCTUBRE",
    "NOVIEMBRE",
    "DICIEMBRE",
  ];

  // const { activities } = useActivitiesRepository();
  const navigate = useNavigate();
  const sortedSlots = slots.sort((a, b) => a.order - b.order);

  const { register, unregister, handleSubmit, formState } = useForm({
    mode: "onTouched",
    // resolver: zodResolver(templateSchema),
  });

  const { errors, isValid } = formState;

  const saveExercises = (slot) => {
    setTotalExercises((prevExercises) => {
      let newExercises = [...prevExercises];
      slot.exercises.forEach((exercise) => {
        newExercises.push(exercise);
      });
      return newExercises;
    });
  };

  // Extraer ejercicios de la sesión y actualizar el estado defaultExercises
  useEffect(() => {
    if (session) {
      const exercises = Object.keys(session)
        .filter((key) => key.startsWith("exercise_session_"))
        .map((key) => ({ [key]: session[key] }));

      // Ordenar los ejercicios en función del número en su nombre
      const sortedExercises = exercises.sort((a, b) => {
        const getOrder = (exercise) => {
          const key = Object.keys(exercise)[0];
          const match = key.match(/exercise_session_(\d+)/);
          return match ? parseInt(match[1], 10) : 0;
        };
        return getOrder(a) - getOrder(b);
      });

      setDefaultExercises(sortedExercises);
    }
  }, [session]);

  useEffect(() => {
    async function fetchSession() {
      const sessionData = await sessionsRepository.getSessionById(session_id);
      setSession(sessionData);
    }
    fetchSession();
  }, [session_id, sessionsRepository]);

  useEffect(() => {
    async function getSessionTemplate() {
      const sessionTemplate = templates?.find(
        (template) => template?.template_id === session?.template_id
      );
      setCurrentTemplateId(sessionTemplate?.template_id);
    }
    getSessionTemplate();
  }, [session, templates]);

  useEffect(() => {
    const fetchSlots = async () => {
      try {
        const slots = await templatesRepository.getTemplateSlotsByTemplateId(
          currentTemplateId
        );
        setSlots(slots);
        setTotalExercises([]);
        slots.forEach((slot) => {
          saveExercises(slot);
        });
      } catch (error) {
        console.error("Error fetching slots:", error);
      }
    };

    fetchSlots();
  }, [currentTemplateId, templatesRepository]);

  useEffect(() => {
    // Desregistrar campos anteriores
    slots.forEach((slot) => {
      slot.exercises.forEach((exercise, index) => {
        unregister(`exercise${exercise.exercise_id}_session`);
      });
    });

    // Crear nuevos campos
    const newFields = [];
    slots.forEach((slot) => {
      slot.exercises.forEach((exercise, index) => {
        newFields.push(exercise.exercise_id);
      });
    });
    setInputValues(newFields.reduce((acc, id) => ({ ...acc, [id]: "" }), {}));
    setAllFieldsValid(
      newFields.reduce((acc, id) => ({ ...acc, [id]: false }), {})
    );
  }, [slots, unregister]);

  // Efecto para verificar si todos los campos son válidos
  useEffect(() => {
    const allValid = Object.values(allFieldsValid).every(Boolean);
    setIsAble(allValid);
  }, [allFieldsValid]);

  // Función para formatear el ID
  const formatId = (number) => {
    return `T${number.toString().padStart(4, "0")}`;
  };

  // Función para comprobar el valor del input
  const checkFilter = (value, exercise) => {
    const formattedId = formatId(value);

    const matchingExerciseMuscleGroup = allExercises.find(
      (ex) =>
        ex.id === formattedId &&
        (ex.muscleGroup.includes(exercise.filter_body_part) ||
          exercise.filter_body_part === "")
    );
    if (matchingExerciseMuscleGroup) {
      const matchingExerciseMaterial = allExercises.find(
        (ex) =>
          ex.id === formattedId &&
          (ex.material.includes(exercise.filter_material) ||
            exercise.filter_material === "")
      );
      if (matchingExerciseMaterial) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  // Manejar el cambio del input
  const handleInputChange = (e, exercise) => {
    const value = e.target.value;
    setInputValues((prevValues) => ({
      ...prevValues,
      [exercise.exercise_id]: value,
    }));

    const isValid = checkFilter(value, exercise);
    setAllFieldsValid((prevValues) => ({
      ...prevValues,
      [exercise.exercise_id]: isValid,
    }));
  };

  const handleUpdateSession = async (form, sessionId) => {
    try {
      const user_id = user.uid;
      const modified_at = new Date().toISOString();
      const currentYear = new Date().getFullYear();
      const sessionDate = `${form.day} de ${form.month} de ${currentYear}`;
      const status = "Pendiente";

      // Renombrar los ejercicios según el orden de entrada
      const exerciseKeys = Object.keys(form).filter(
        (key) => key.startsWith("exercise") && key.endsWith("_session")
      );
      const renamedExercises = {};
      exerciseKeys.forEach((key, index) => {
        renamedExercises[`exercise_session_${index + 1}`] = form[key];
      });

      // Eliminar las claves originales de los ejercicios
      exerciseKeys.forEach((key) => {
        delete form[key];
      });

      delete form.template_id;

      const formUpdated = {
        ...form,
        ...renamedExercises,
        user_id,
        session_id,
        modified_at,
        sessionDate,
        status,
      };
      await sessionsRepository.updateSession(formUpdated, session_id);
      toast.success("Plantilla modificada correctamente.");
      navigate("/sessions");
    } catch (error) {
      toast.error("Error al modificar la plantilla.");
    }
  };

  // Dividir defaultExercises en subarrays para cada slot
  const assignExercisesToSlots = (slots, defaultExercises) => {
    let index = 0;
    return slots.map((slot) => {
      const exercisesForSlot = defaultExercises.slice(
        index,
        index + slot.exercises.length
      );
      index += slot.exercises.length;
      return { ...slot, exercisesForSlot };
    });
  };

  const handleDeleteSession = async () => {
    try {
      await sessionsRepository.deleteSession(session_id);
      toast.success("Sesión eliminada correctamente");
      navigate("/sessions");
    } catch (error) {
      console.error("Error deleting session:", error);
      toast.error("Error al eliminar la sesión");
    }
  };

  const slotsWithExercises = assignExercisesToSlots(
    sortedSlots,
    defaultExercises
  );

  // Verificar si defaultExercises tiene contenido
  if (defaultExercises.length === 0) {
    return <h1>Cargando...</h1>; // Mostrar un indicador de carga mientras se cargan los datos
  }

  return (
    <form onSubmit={handleSubmit(handleUpdateSession)}>
      <div className="space-y-12 max-w-4xl mx-auto">
        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Modifica la sesión
          </h2>
          <div className="sm:col-span-4">
            <label
              htmlFor="template_name"
              className="mt-5 block text-sm font-medium leading-6 text-gray-900"
            >
              Plantilla
            </label>
            <div className="mt-2">
              <select
                id="template_id"
                name="template_id"
                defaultValue={session?.template?.template_id || ""}
                {...register("template_id")}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
              >
                <option>{session?.template?.template_name}</option>
              </select>
            </div>
          </div>
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-4">
              <label
                htmlFor="day"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Día
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    id="day"
                    name="day"
                    type="number"
                    defaultValue={session?.day}
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    {...register("day", {
                      valueAsNumber: true,
                      min: {
                        value: 1,
                        message: "El día debe ser al menos 1",
                      },
                      max: {
                        value: 31,
                        message: "El día no puede ser mayor que 31",
                      },
                      required: "El día es obligatorio",
                    })}
                  />
                </div>
                {errors.day && (
                  <p className="h-4 text-sm text-red-500">
                    {errors.day.message}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="sm:col-span-4">
            <label
              htmlFor="template_name"
              className="mt-5 block text-sm font-medium leading-6 text-gray-900"
            >
              Mes
            </label>
            <div className="mt-2">
              <select
                id="month"
                name="month"
                defaultValue={session?.month}
                {...register("month")}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
              >
                {months?.map((month, index) => (
                  <option key={index} value={month}>
                    {month}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {slotsWithExercises?.map((slot) => {
            return (
              <GenerateUpdateSessionExercises
                key={slot.slot_id}
                slot={slot}
                register={register}
                errors={errors}
                handleInputChange={handleInputChange}
                defaultExercises={slot.exercisesForSlot}
              />
            );
          })}
          {user.role !== "admin" && session?.correction_text && (
            <div className="mt-10 sm:col-span-4">
              <label
                htmlFor="day"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Comentario para la corrección:
              </label>
              <div className="mt-2">
                <p className="block text-sm leading-6 text-gray-900">
                  {session?.correction_text}
                </p>
              </div>
            </div>
          )}
          {user.role === "admin" && (
            <div className="mt-10 sm:col-span-4">
              <label
                htmlFor="day"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Comentario para la corrección:
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    id="correction_text"
                    name="correction_text"
                    defaultValue={session?.correction_text}
                    type="text"
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    {...register("correction_text")}
                  />
                </div>
                {errors.correction_text && (
                  <p className="h-4 text-sm text-red-500">
                    {errors.correction_text.message}
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button
          type="submit"
          disabled={!isAble}
          className={`rounded-md ${
            !isAble ? "bg-red-600" : "bg-indigo-500"
          } px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2`}
        >
          Modificar
        </button>
        <button
          onClick={handleDeleteSession}
          type="button"
          className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2"
        >
          Borrar
        </button>
      </div>
    </form>
  );
}

export default UpdateSessionForm;
