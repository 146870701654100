import {
  child,
  ref as databaseRef,
  equalTo,
  get,
  orderByChild,
  query,
  remove,
  update,
} from "firebase/database";
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  listAll,
  ref as storageRef,
  uploadBytes,
} from "firebase/storage";
import { atom } from "nanostores";

import firebase from "../../shared/firebase";

class TemplatesRepositoryFirebase {
  constructor() {
    this.stores = {
      templates: atom(null),
    };

    this.getTemplates();
  }

  async getTemplates() {
    let templates = [];
    try {
      const templatesPath = `/templates/`;
      const templatesSnapshot = await get(
        child(databaseRef(firebase.db), templatesPath)
      );

      if (templatesSnapshot.exists()) {
        templates = Object.values(templatesSnapshot.val());

        for (const template of templates) {
          let activity;
          const activityPath = `/activities/${template.activity_id}`;
          const activitySnapshot = await get(
            child(databaseRef(firebase.db), activityPath)
          );
          if (activitySnapshot.exists()) {
            activity = activitySnapshot.val();
          }
          template.activity = activity;
        }
        this.stores.templates.set(templates);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getTemplateById(id) {
    let template = null;
    try {
      const templatePath = `/templates/${id}`;
      const templateSnapshot = await get(
        child(databaseRef(firebase.db), templatePath)
      );

      if (templateSnapshot.exists()) {
        template = templateSnapshot.val();
      }
    } catch (error) {
      console.error(error);
    }

    return template;
  }

  async updateTemplate(id, data) {
    try {
      const templatePath = `/templates/${id}`;
      const templateRef = child(databaseRef(firebase.db), templatePath);

      // Obtener el documento actual
      const snapshot = await get(templateRef);
      if (!snapshot.exists()) {
        throw new Error("Template not found");
      }
      const currentTemplate = snapshot.val();

      // Verificar si hay un template_logo anterior y eliminarlo
      if (currentTemplate?.template_logo && data?.image[0]?.name) {
        // Obtener la referencia a la carpeta de imágenes de la compañía en Firebase Storage
        const storage = getStorage();
        const folderRef = storageRef(storage, `templates/${id}`);

        // Listar todos los archivos en la carpeta
        const listResult = await listAll(folderRef);

        // Eliminar cada archivo en la carpeta
        const deletePromises = listResult.items.map((fileRef) =>
          deleteObject(fileRef)
        );
        await Promise.all(deletePromises);
      }

      // Verificar si data contiene un archivo en la propiedad template_logo
      if (
        data.image &&
        data.image.length > 0 &&
        data.image[0] instanceof File
      ) {
        const storage = getStorage();
        const fileRef = storageRef(
          storage,
          `templates/${id}/${data.image[0].name}`
        );

        // Subir el archivo a Firebase Storage
        await uploadBytes(fileRef, data.image[0]);

        // Obtener la URL de descarga del archivo
        const fileUrl = await getDownloadURL(fileRef);

        // Actualizar data con la URL del archivo
        data.template_logo = fileUrl;
      } else {
        // Mantener la propiedad template_logo si no se subieron cambios
        data.template_logo = currentTemplate.template_logo;
      }

      // Actualizar la base de datos con data
      await update(templateRef, data);
    } catch (error) {
      console.error(error);
    }
  }

  async createTemplate(id, data) {
    try {
      const templatePath = `/templates/${id}`;
      const templateRef = child(databaseRef(firebase.db), templatePath);

      // Verificar si data contiene un archivo en la propiedad template_logo
      if (
        data.image &&
        data.image.length > 0 &&
        data.image[0] instanceof File
      ) {
        const storage = getStorage();
        const fileRef = storageRef(
          storage,
          `templates/${id}/${data.image[0].name}`
        );

        // Subir el archivo a Firebase Storage
        await uploadBytes(fileRef, data.image[0]);

        // Obtener la URL de descarga del archivo
        const fileUrl = await getDownloadURL(fileRef);

        // Actualizar data con la URL del archivo
        data.template_logo = fileUrl;
      }

      // Crear el documento en la base de datos
      await update(templateRef, data);
    } catch (error) {
      console.error(error);
    }
  }
  async deleteTemplate(id) {
    try {
      const templatePath = `/templates/${id}`;
      const templateRef = child(databaseRef(firebase.db), templatePath);

      // Eliminar el documento de la base de datos
      await remove(templateRef);

      // Obtener la referencia a la carpeta de imágenes de la compañía en Firebase Storage
      const storage = getStorage();
      const folderRef = storageRef(storage, `templates/${id}`);

      // Listar todos los archivos en la carpeta
      const listResult = await listAll(folderRef);

      // Eliminar cada archivo en la carpeta
      const deletePromises = listResult.items.map((fileRef) =>
        deleteObject(fileRef)
      );
      await Promise.all(deletePromises);
    } catch (error) {
      console.error(error);
    }
  }

  async createSlotWithExercises(slotId, slotData, exercises) {
    try {
      const slotsRef = child(databaseRef(firebase.db), "slots");
      const slotsQuery = query(
        slotsRef,
        orderByChild("template_id"),
        equalTo(slotData.template_id)
      );
      const snapshot = await get(slotsQuery);

      let order = 1;
      if (snapshot.exists()) {
        const orders = [];
        snapshot.forEach((childSnapshot) => {
          const slot = childSnapshot.val();
          if (slot.order) {
            orders.push(slot.order);
          }
        });

        // Ordenar los números de orden existentes
        orders.sort((a, b) => a - b);

        // Encontrar el número de orden libre más bajo
        for (let i = 1; i <= orders.length + 1; i++) {
          if (!orders.includes(i)) {
            order = i;
            break;
          }
        }
      }

      slotData.order = order;
      const slotPath = `/slots/${slotId}`;
      const slotRef = child(databaseRef(firebase.db), slotPath);

      // Guardar el slot en la colección `slot`
      await update(slotRef, slotData);

      // Guardar cada ejercicio en la colección `slot_exercise`
      const exercisePromises = exercises.map((exercise) => {
        const exercisePath = `/slot_exercises/${exercise.exercise_id}`;
        const exerciseRef = child(databaseRef(firebase.db), exercisePath);
        return update(exerciseRef, exercise);
      });

      await Promise.all(exercisePromises);
    } catch (error) {
      console.error("Error adding slot with exercises:", error);
    }
  }

  async getTemplateSlotsByTemplateId(templateId) {
    let slots = [];
    try {
      const slotsPath = `/slots/`;
      const slotsSnapshot = await get(
        child(databaseRef(firebase.db), slotsPath)
      );

      if (slotsSnapshot.exists()) {
        slots = Object.values(slotsSnapshot.val());
        slots = slots.filter((slot) => slot.template_id === templateId);

        for (const slot of slots) {
          let exercises = [];
          const exercisesPath = `/slot_exercises/`;
          const exercisesSnapshot = await get(
            child(databaseRef(firebase.db), exercisesPath)
          );
          if (exercisesSnapshot.exists()) {
            exercises = Object.values(exercisesSnapshot.val());
            exercises = exercises.filter(
              (exercise) => exercise.slot_id === slot.slot_id
            );
            slot.exercises = exercises;
          }
        }
        return slots;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async deleteSlot(slotId) {
    try {
      const slotPath = `/slots/${slotId}`;
      const slotRef = child(databaseRef(firebase.db), slotPath);

      // Eliminar el documento de la base de datos
      await remove(slotRef);

      // Eliminar los ejercicios asociados al slot
      const exercisesPath = `/slot_exercises/`;
      const exercisesRef = child(databaseRef(firebase.db), exercisesPath);
      const exercisesQuery = query(
        exercisesRef,
        orderByChild("slot_id"),
        equalTo(slotId)
      );
      const exercisesSnapshot = await get(exercisesQuery);

      if (exercisesSnapshot.exists()) {
        const exercises = Object.values(exercisesSnapshot.val());
        for (const exercise of exercises) {
          const exercisePath = `/slot_exercises/${exercise.exercise_id}`;
          const exerciseRef = child(databaseRef(firebase.db), exercisePath);
          await remove(exerciseRef);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
}

export default TemplatesRepositoryFirebase;
