import { z } from "zod";

const userRegisteredSchema = z.object({
  name: z
    .string()
    .min(1, "Campo obligatorio")
    .min(3, "Necesita mas de 3 caracteres")
    .max(50, "Máximo 50 caracteres"),
  company_id: z
    .string()
    .min(1, "Campo obligatorio")
    .min(3, "Necesita mas de 3 caracteres")
    .max(100, "Máximo 100 caracteres"),
  email: z
    .string()
    .min(1, "Campo obligatorio")
    .email("Debe ser un email válido"),
  uid: z
    .string()
    .min(3, "Necesita mas de 3 caracteres")
    .max(100, "Máximo 100 caracteres")
    .optional(),
  pass: z.string().max(16, "Debe contener entre 8 y 16 caracteres").optional(),
  role: z.enum(
    ["admin", "trainer", "editor", "manager"],
    "El rol debe coincidir con los valores preestablecidos"
  ),
});

export default userRegisteredSchema;
