import { z } from "zod";

const templateSchema = z.object({
  template_id: z
    .string()
    .min(1, "Campo obligatorio")
    .min(3, "Necesita mas de 3 caracteres")
    .max(100, "Máximo 100 caracteres")
    .optional(),
  activity_id: z
    .string()
    .min(1, "Campo obligatorio")
    .min(3, "Necesita mas de 3 caracteres")
    .max(100, "Máximo 100 caracteres")
    .optional(),
  template_name: z
    .string()
    .min(1, "Campo obligatorio")
    .min(3, "Necesita mas de 3 caracteres")
    .max(50, "Máximo 50 caracteres"),
  composition_name: z
    .string()
    .min(1, "Campo obligatorio")
    .min(3, "Necesita mas de 3 caracteres")
    .max(100, "Máximo 100 caracteres"),
  composition_name_2: z
    .string()
    .min(1, "Campo obligatorio")
    .min(3, "Necesita mas de 3 caracteres")
    .max(100, "Máximo 100 caracteres"),
  image: z.any().optional(),
});

export default templateSchema;
