import { useAuthRepository } from "../../repositories/auth/authRepository.js";

import CreateTemplateForm from "./components/CreateTemplateForm.js";

function TemplateCreate() {
  const { user } = useAuthRepository();

  return user.role === "admin" ? (
    <CreateTemplateForm />
  ) : (
    <div>No tienes acceso a esta sección</div>
  );
}

export default TemplateCreate;
