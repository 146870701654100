import { useStore } from "@nanostores/react";
import { useEffect, useState } from "react";

import ActivitiesRepositoryFirebase from "./ActivitiesRepositoryFirebase";

const activitiesRepositoryFirebase = new ActivitiesRepositoryFirebase();

function useActivitiesRepository() {
  const [activitiesRepository] = useState(activitiesRepositoryFirebase);
  const activities = useStore(activitiesRepository.stores.activities);

  useEffect(() => {
    activitiesRepository.getActivities();
  }, [activitiesRepository]);
  return { activities, activitiesRepository };
}

export { useActivitiesRepository };
