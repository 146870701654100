import exampleExcercice14Src from "./example-exercises/T0014.gif";
import exampleExcercice22Src from "./example-exercises/T0022.gif";
import exampleExcercice25Src from "./example-exercises/T0025.gif";
import exampleExcercice41Src from "./example-exercises/T0041.gif";
import exampleExcercice52Src from "./example-exercises/T0052.gif";
import exampleExcercice62Src from "./example-exercises/T0062.gif";
import exampleExcercice386Src from "./example-exercises/T0386.gif";

function DecorativeImageGrid() {
  return (
    <div
      aria-hidden="true"
      className="pointer-events-none absolute lg:inset-y-0 lg:mx-auto lg:w-full lg:max-w-7xl left-0 hidden lg:block"
    >
      <div className="absolute transform sm:left-1/2 sm:top-0 lg:left-1/2 lg:top-1/2 lg:-translate-y-1/2">
        <div className="flex items-center space-x-6 lg:space-x-8">
          <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
            <div className="h-52 w-48 overflow-hidden rounded-lg sm:opacity-0 lg:opacity-100 bg-gray-100">
              <img
                src={exampleExcercice14Src}
                alt=""
                className="h-full w-full object-cover mix-blend-multiply"
              />
            </div>
            <div className="h-52 w-48 overflow-hidden rounded-lg sm:opacity-0 lg:opacity-100 bg-gray-100">
              <img
                src={exampleExcercice25Src}
                alt=""
                className="h-full w-full object-cover mix-blend-multiply"
              />
            </div>
          </div>
          <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
            <div className="h-52 w-48 overflow-hidden rounded-lg sm:opacity-0 lg:opacity-100 bg-gray-100">
              <img
                src={exampleExcercice52Src}
                alt=""
                className="h-full w-full object-cover mix-blend-multiply"
              />
            </div>
            <div className="h-52 w-48 overflow-hidden rounded-lg sm:opacity-0 lg:opacity-100 bg-gray-100">
              <img
                src={exampleExcercice22Src}
                alt=""
                className="h-full w-full object-cover mix-blend-multiply"
              />
            </div>
            <div className="h-52 w-48 overflow-hidden rounded-lg sm:opacity-0 lg:opacity-100 bg-gray-100">
              <img
                src={exampleExcercice41Src}
                alt=""
                className="h-full w-full object-cover mix-blend-multiply"
              />
            </div>
          </div>
          <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
            <div className="h-52 w-48 overflow-hidden rounded-lg sm:opacity-0 lg:opacity-100 bg-gray-100">
              <img
                src={exampleExcercice386Src}
                alt=""
                className="h-full w-full object-cover mix-blend-multiply"
              />
            </div>
            <div className="h-52 w-48 overflow-hidden rounded-lg sm:opacity-0 lg:opacity-100 bg-gray-100">
              <img
                src={exampleExcercice62Src}
                alt=""
                className="h-full w-full object-cover mix-blend-multiply"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DecorativeImageGrid;
